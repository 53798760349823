import React from 'react'
import Modal, { ModalActions } from '../../modal'
import { User, UserRoleKind } from '../../../api/users'
import { Element } from 'react-bulma-components'
import UserForm from '../user-form'

interface UpdateUserModalProps {
  modalActions: ModalActions
  user: User
  userRole: UserRoleKind
}

const UpdateUserModal: React.FC<UpdateUserModalProps> = ({ user, modalActions, userRole }) => {
  return (
    <Modal
      title={`Modifier les données de ${user?.firstName} ${user?.lastName}`}
      actions={modalActions}
    >
      <Element>
        <UserForm role={userRole} user={user} noAppAccess={user?.appAccess === 'none'} />
      </Element>
    </Modal>
  )
}

export default UpdateUserModal
