import { once } from 'lodash'
import React, { ReactNode, useEffect, useState } from 'react'

const minCountBackSeconds = 0
const maxCountBackSeconds = 60
const defaultCountBackSeconds = 3

type UseCountBack = (seconds?: CountBackProps['seconds'], cb?: CountBackProps['cb']) => number

const useCountBack: UseCountBack = (seconds = defaultCountBackSeconds, cb) => {
  const [countBack, setCountBack] = useState(seconds)

  if (seconds < minCountBackSeconds || seconds > maxCountBackSeconds)
    throw new Error(
      `Please provide a positive integer time value between ${minCountBackSeconds} and ${maxCountBackSeconds}  for useCountBack hook, provided : ${seconds}`,
    )

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (countBack > 0) {
      timeout = setTimeout(() => setCountBack(cB => cB - 1), 1000)
    }
    return () => clearTimeout(timeout)
  }, [countBack])

  if (countBack === 0 && cb) {
    once(cb)()
  }

  return countBack
}

interface CountBackAndHideProps extends CountBackProps {
  children?: ReactNode
}

export const CountBackAndHide: React.FC<CountBackAndHideProps> = ({
  seconds = defaultCountBackSeconds,
  cb,
  children,
}) => {
  const countBack = useCountBack(seconds, cb)
  if (countBack > 0) return <>{children}</>
  return <></>
}

interface CountBackProps {
  seconds?: number
  cb?: () => void
}

export const CountBack: React.FC<CountBackProps> = ({ seconds = defaultCountBackSeconds, cb }) => {
  const countBack = useCountBack(seconds, cb)
  return countBack
}
