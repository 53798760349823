import React from 'react'
import { Element, Tag } from 'react-bulma-components'
import { UserRole } from '../../../../backend/src/services/resources/users/user.model'
import { formatCompleteDateTime } from '../../utils/date'
import locales from '../../../../locales/users'
import { UserAssociation } from '../../api/users'

interface RoleStatusProps {
  role: UserRole | UserAssociation | { status: UserRole['status']; updatedAt?: Date }
  showDate?: boolean
}

const INVITATION_STATUS_COLORS = {
  //TODO: move in to config file?
  accepted: 'success',
  pending: 'info',
  rejected: 'danger',
  discontinued: 'dark',
}

const RoleStatus: React.FC<RoleStatusProps> = ({ role, showDate }) => {
  return (
    <Element data-tooltip={showDate && role.updatedAt && formatCompleteDateTime(role.updatedAt)}>
      <Tag color={INVITATION_STATUS_COLORS[role.status]}>{locales.roles.status[role.status]}</Tag>{' '}
      {/*showDate && (
        <Element renderAs="span" textSize={7}>
          {formatCompleteDateTime(role.updatedAt)}
        </Element>
      )*/}
    </Element>
  )
}

export default RoleStatus
