import React, { useEffect, useState } from 'react'
import {
  Button,
  Columns,
  Container,
  Element,
  Heading,
  Icon,
  Level,
  Message,
  Section,
  Tabs,
} from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import { Field, Form, Formik } from 'formik'
import AutoSubmit from '../../components/form/utils/auto-submit'
import { RefreshIcon } from '@heroicons/react/solid'
import Clock from '../../components/clock/clock'
import { useCreateMutation } from '../../queries/clockings'
import { useParams } from 'react-router-dom'
import { CheckCircleIcon } from '@heroicons/react/outline'
import QrScannerField from '../../components/form/fields/qr-scanner'
import { CountBack, CountBackAndHide } from '../../hooks/use-count-back'
//@ts-expect-error -> this is an mp3 file !
import * as BeepFile from '../../images/beep.mp3'

const beep = new Audio(BeepFile)
const usePlayBeep = () => {
  useEffect(() => {
    beep.play()
    return () => {
      beep.pause()
    }
  }, [])
}

const Beep = () => {
  usePlayBeep()
  return <></>
}

const QrScannerPage: React.FunctionComponent = () => {
  const mutation = useCreateMutation()
  const [qrReaderError, setQrReaderError] = useState<string>()

  const organizationName = useParams()['name']

  const [camera, setCamera] = React.useState<'user' | 'environment' | undefined>('environment')
  const [mode, setMode] = React.useState<'kiosk' | 'mobile' | undefined>('kiosk')

  return (
    <Layout hideHeader hideFooter>
      <Formik<{ qr: string }>
        initialValues={{
          qr: '',
        }}
        onSubmit={async (values, { resetForm }) => {
          if (values.qr) {
            try {
              const hash = JSON.parse(values.qr)
              if (hash.content && hash.iv)
                await mutation
                  .mutateAsync({
                    hash: JSON.parse(values.qr),
                    employer: organizationName,
                  })
                  .catch(e => e)
            } catch (err) {
              setQrReaderError("Ce QR Code n'est pas valide")
            }
            resetForm({
              values: { qr: '' },
            })
          }
        }}
      >
        {props => {
          return (
            <Section py={5}>
              <Container>
                <Columns centered className="is-mobile">
                  <Columns.Column
                    style={{ maxWidth: 'min(min(75vh, 100vw), 900px)', minWidth: 320 }}
                    textSize={4}
                    textWeight="bold"
                  >
                    {qrReaderError && (
                      <CountBackAndHide cb={() => setQrReaderError(undefined)}>
                        <Beep />
                        <Message color="danger" mb={3}>
                          <Message.Body>
                            {qrReaderError} (<CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {mutation.isSuccess && (
                      <CountBackAndHide cb={() => mutation.reset()}>
                        <Beep />
                        <Message color="success" mb={3}>
                          <Message.Body>
                            {mutation.data.data.mission
                              ? 'Pointage enregistré'
                              : 'Aucune mission en cours'}
                            &nbsp;(
                            <CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {mutation.isError && (
                      <CountBackAndHide cb={() => mutation.reset()}>
                        <Beep />
                        <Message color="danger" mb={3}>
                          <Message.Body>
                            Pas autorisé (<CountBack />)
                          </Message.Body>
                        </Message>
                      </CountBackAndHide>
                    )}
                    {!qrReaderError && !mutation.isSuccess && !mutation.isError && (
                      <Message color="white" mb={3}>
                        <Message.Body textAlign={'center'}>
                          Scanner votre QR à <Clock />
                        </Message.Body>
                      </Message>
                    )}

                    <Form>
                      <>
                        {
                          <>
                            <Element style={{ transform: mode === 'kiosk' ? 'scaleX(-1)' : '' }}>
                              <Field
                                name="qr"
                                component={QrScannerField}
                                setQrReaderError={setQrReaderError}
                                required
                                facingMode={camera}
                              />
                            </Element>
                            <Element px={2} mt={5}>
                              <Level breakpoint="mobile">
                                <Level.Side>
                                  <Tabs size={'small'} type="toggle-rounded" color="primary">
                                    <Tabs.Tab
                                      active={mode === 'kiosk'}
                                      onClick={() => setMode('kiosk')}
                                      backgroundColor={mode !== 'kiosk' ? 'light' : undefined}
                                    >
                                      {mode === 'kiosk' && (
                                        <Icon>
                                          <CheckCircleIcon color="success" />
                                        </Icon>
                                      )}
                                      Kiosque
                                    </Tabs.Tab>
                                    <Tabs.Tab
                                      active={mode === 'mobile'}
                                      onClick={() => setMode('mobile')}
                                      backgroundColor={mode !== 'mobile' ? 'light' : undefined}
                                    >
                                      {mode === 'mobile' && (
                                        <Icon>
                                          <CheckCircleIcon />
                                        </Icon>
                                      )}
                                      Mobile
                                    </Tabs.Tab>
                                  </Tabs>
                                </Level.Side>
                                <Level.Side>
                                  <Tabs size="small" type="toggle-rounded">
                                    <Tabs.Tab
                                      backgroundColor="white"
                                      onClick={() =>
                                        setCamera(camera === 'user' ? 'environment' : 'user')
                                      }
                                    >
                                      <Icon color="primary">
                                        <RefreshIcon />
                                      </Icon>
                                    </Tabs.Tab>
                                  </Tabs>
                                </Level.Side>
                              </Level>
                            </Element>
                          </>
                        }
                      </>
                      <input
                        type="text"
                        name="qr"
                        onChange={props.handleChange}
                        value={props.values.qr}
                        style={{ border: 'none' }}
                      />
                      <AutoSubmit values={props.values} submitForm={props.submitForm} />
                    </Form>
                  </Columns.Column>
                </Columns>
              </Container>
            </Section>
          )
        }}
      </Formik>
    </Layout>
  )
}

export default QrScannerPage
