import {
  add,
  addDays,
  addHours,
  addMinutes,
  eachDayOfInterval,
  endOfWeek as fnsEndOfWeek,
  format,
  getWeek as fnsGetWeek,
  setWeek as fnsSetWeek,
  getWeekYear as fsGetWeekYear,
  getYear,
  intervalToDuration,
  isBefore,
  startOfDay,
  startOfWeek as fnsStartOfWeek,
  startOfYear,
  parseISO,
  setDefaultOptions,
  startOfMonth,
  endOfMonth,
} from 'date-fns'
import { fr } from 'date-fns/locale'
import { padStart, upperFirst } from 'lodash'

/**
 * Global date defaults
 */
setDefaultOptions({ locale: fr, weekStartsOn: 1 })

export type WeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6

//Convert date to HTML time value format
export const dateToTimeInputValue = (date?: Date): string => {
  if (!date) return ''
  return new Date(date).toLocaleString('fr-FR', {
    hour: 'numeric',
    minute: 'numeric',
  })
}

/**
 * Get a given date set to a given time
 * @param   {Date}                        date
 * @param   {<input type='time'>.value}   time an HTML time string of type 'HH:MM' in 24h format, "" is valid and means input is empty
 * @return  {undefined}                   if time couldn't be parsed
 * @return  {null}                        if time value is an empty input[type=time] default value : ""
 * @return  {Date}                        the given date, set to the given time
 */
export const timeInputValueToDate = (date: Date, time: string): Date | null | undefined => {
  // The HTML time input field is blank
  if (time === '') return null
  // Matching HH:MM in 24h time format with leading zero : https://stackoverflow.com/a/51177696
  const match = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.exec(time)
  // We return undefined if the provided time was not parseable
  if (!match) return
  // We get the hours and minutes from the string and parse them as numbers
  const [hours, minutes] = time.split(':').map(number => Number(number))
  return addMinutes(addHours(startOfDay(new Date(date)), hours), minutes)
}

/**
 * Takes a date, and from/to time, and returns a 24h date interval set to from/to time using @see timeInputValueToDate
 * If @fromDate couldn't be computed it still computes the 24h interval using @date
 * @example ('2023-03-16T00:00:00.000Z', '23:00', '05:00') => ['2023-03-16T23:00:00.000Z', ''2023-03-17T05:00:00.000Z'']
 * @example ('2023-03-16T00:00:00.000Z', '', '05:00') => [null, ''2023-03-17T05:00:00.000Z'']
 * @param   {Date}                      date a Date Object
 * @param   {<input type='time'>.value} fromTime an HTML time string of type 'HH:MM' in 24h format, "" is valid and means input is empty
 * @param   {<input type='time'>.value} toTime an HTML time string of type 'HH:MM' in 24h format, "" is valid and means input is empty
 * @return  {[fromDate, toDate]} if @fromDate is set with @toDate - @fromDate < 24h00
 */
export const fromToTimeInputsValueToDates = (
  date: Date,
  fromTime: string,
  toTime: string,
): [Date | null | undefined, Date | null | undefined] => {
  const fromDate = timeInputValueToDate(date, fromTime)
  const toDate = timeInputValueToDate(date, toTime)
  const baseDate = new Date(date)

  // Handling cases where toTime is the next day of fromTime, e.g. past midnight
  // If no fromDate could be computed, we still evaluate toDate against the original date provided
  if (!fromDate && toDate && isBefore(toDate, baseDate)) return [fromDate, addDays(toDate, 1)]
  if (fromDate && toDate && toDate < fromDate) return [fromDate, addDays(toDate, 1)]

  return [fromDate, toDate]
}

//Return week from to label : Semaine x - du y au z
const buildWeekLabel = (date: Date): string =>
  `Semaine ${getWeek(date)} - du 
  ${format(startOfWeek(date), 'dd/MM/yyyy')} au 
  ${format(endOfWeek(date), 'dd/MM/yyyy')}`

// Convert date to HTML week value format
const dateToWeekInputValue = (date: Date): string => `${getYear(date)}-W${getWeek(date)}`

// Convert value returned by HTML week input (ex: 2021-W20) intos start and end dates
const weekInputValueToDates = (value?: string): { start: Date; end: Date } | undefined => {
  if (!value) value = dateToWeekInputValue(new Date())
  const match = /([0-9]*)-W([0-9]*)/.exec(value)
  if (!match) return

  const startWeekDate = startOfWeek(
    add(startOfYear(new Date(Number(match[1]), 0, 1)), {
      weeks: Number(match[2]),
    }),
  )
  return {
    start: startWeekDate,
    end: new Date(add(startWeekDate, { weeks: 1 }).getTime() - 1),
  }
}

// Convert value returned by HTML date input (ex: 2021-12-15) into start and end dates
export const dateInputValuesToWeekDates = (value?: string): { start: Date; end: Date } => {
  const date = value ? new Date(value) : new Date()
  return {
    start: startOfWeek(date),
    end: endOfWeek(date),
  }
}

const dateInputValuesToMonthDates = (value?: string): { start: Date; end: Date } => {
  const date = value ? new Date(value) : new Date()
  return {
    start: startOfMonth(date),
    end: endOfMonth(date),
  }
}

// Convert date to HTML date input value (ex: 2021-12-15)
export const dateToDateInputValue = (date?: Date): string => {
  if (!date) date = new Date()
  return format(date, 'yyyy-MM-dd')
}

// Convert HTML date input value (ex: 2021-12-15) date to date object
export const dateInputToDateValue = (date: string): Date => {
  return parseISO(date)
}

/* date-fns wrappers */
type DateParam = Date | string | number
const buildDate = (date?: DateParam): Date => {
  if (!date) return new Date()
  else if (date instanceof Date) return date
  return new Date(date)
}

// Get week number of a date. If no date is passed, the date treated is today
export const getWeek = (date?: DateParam): number => fnsGetWeek(buildDate(date), { locale: fr })

export const getWeekYear = (date: Date): number => fsGetWeekYear(date, { locale: fr })

// Get week number of a date. If no date is passed, the date treated is today
export const setWeek = (date: DateParam, week: number): Date =>
  fnsSetWeek(buildDate(date), week, { locale: fr })

// Get start of week
export const startOfWeek = (date?: DateParam): Date =>
  fnsStartOfWeek(buildDate(date), { weekStartsOn: 1 })

// Get end of week
export const endOfWeek = (date?: DateParam): Date =>
  fnsEndOfWeek(buildDate(date), { weekStartsOn: 1 })

// Corrects the weekday from sunday-based to monday-based
export const getWeekdayMondayBased = (weekdaySundayBased: WeekDay): WeekDay =>
  // If weekDay is 0, we are sunday !
  weekdaySundayBased === 0 ? 6 : ((weekdaySundayBased - 1) as WeekDay)

//Formats

//Ex: mercredi 01 décembre 2021 12:00
export const formatCompleteDateTime = (date?: DateParam): string =>
  date ? format(buildDate(date), "EEEE dd MMMM yyyy H'h'mm", { locale: fr }) : ''

//Ex: mercredi 01 décembre 12:00
export const formatShortDateTime = (date?: DateParam): string =>
  date ? format(buildDate(date), 'dd/MM/yy H:mm:ss', { locale: fr }) : ''

//Ex: mercredi 01 décembre 2021
export const formatCompleteDate = (date?: DateParam): string =>
  date ? format(buildDate(date), 'EEEE dd MMMM yyyy', { locale: fr }) : ''

//Ex: mercredi 01 décembre 12:00
const formatShortDate = (date?: DateParam): string =>
  date ? format(buildDate(date), 'dd/MM/yy', { locale: fr }) : ''

export const formatTime = (date?: DateParam): string =>
  date ? format(buildDate(date), "HH'h'mm", { locale: fr }) : ''

export const localDate = (date?: DateParam): string =>
  new Date(buildDate(date)).toLocaleDateString()

export const formatDuration = (timestamp: number): string => {
  const duration = intervalToDuration({ start: 0, end: timestamp })
  return `${padStart(
    ((duration.hours || 0) + (duration.days || 0) * 24).toString(),
    2,
    '0',
  )}h${padStart((duration.minutes || 0).toString(), 2, '0')}`
}

export const formatDay = (date?: DateParam): string =>
  date ? upperFirst(format(buildDate(date), 'E d', { locale: fr })) : ''

export const formatDayName = (date?: DateParam): string =>
  date ? upperFirst(format(buildDate(date), 'EEEE', { locale: fr })) : ''

//formatInTimeZone(new Date(duration), 'UTC', "HH'h'mm")

export const allDaysOfInterval = (start: DateParam, end: DateParam): Date[] => {
  return eachDayOfInterval({ start: buildDate(start), end: buildDate(end) })
}

export const allDaysOfCurrentWeek = allDaysOfInterval(
  startOfWeek(new Date()),
  endOfWeek(new Date()),
)

//Week days as input options
export const weekDaysAsInputOptions = (days: Date[]): Partial<HTMLOptionElement>[] => [
  { value: '', label: 'Toute la semaine', defaultSelected: true },
  ...days.map(day => ({
    value: day.toString(),
    label: formatDay(day),
  })),
]

export const convertDateToUTCKeepingTime = (date: Date): Date =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  )
