import React from 'react'

interface FormikObserverProps {
  onChange?(values: any): void
  values: any
}

const FormikObserver: React.FC<FormikObserverProps> = ({ onChange, values }) => {
  React.useEffect(() => {
    onChange && onChange(values)
  }, [values])
  return null
}

export default FormikObserver
