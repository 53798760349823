import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import useStore from '../store'

const Guest: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isAuthenticated = useStore(state => state.session.isAuthenticated)
  const navigate = useNavigate()

  // Listen session data to check if current user can access to current route
  React.useEffect(() => {
    //Redirect to / if current user is authenticated
    if (isAuthenticated) navigate('/')
  }, [isAuthenticated])

  return <>{children}</>
}

export default Guest
