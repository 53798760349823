import React from 'react'
import { Field, FieldProps, Formik } from 'formik'
import FormField, { FormFieldProps } from './form-field'
import AutocompleteField, { AutocompleteFieldItem } from './autocomplete'
import FormikObserver from '../formik-observer'
import { Button, Element, Icon } from 'react-bulma-components'
import { DraggableListIcon, RemoveIcon } from '../../icons'
import { moveItemInArray } from '../../../utils/arrays'
import { List } from 'react-movable'
import _get from 'lodash/get'

interface ListFieldProps extends FormFieldProps, FieldProps {
  items: AutocompleteFieldItem[]
  defaultSelectedItems?: AutocompleteFieldItem[]
}

const ListField: React.FC<ListFieldProps> = ({
  label,
  form,
  field,
  items,
  defaultSelectedItems,
}) => {
  const { name } = field
  const [listItems, setListItems] = React.useState<AutocompleteFieldItem[]>(
    defaultSelectedItems || [],
  )

  const addItemToList = (itemValue: string) => {
    const item = items.find(i => i.value === itemValue)
    if (item) setListItems([...listItems, item])
  }

  const removeItemFromList = (itemValue: string) => {
    setListItems(listItems.filter(i => i.value !== itemValue))
  }

  const moveItemInList = (itemIndex: number, moveTo: number) => {
    setListItems(moveItemInArray([...listItems], itemIndex, moveTo))
  }

  const selectableItems = React.useMemo(
    () => items.filter(item => !listItems.some(listItem => listItem.value === item.value)),
    [items, listItems],
  )

  React.useEffect(() => {
    form.setFieldValue(
      field.name,
      listItems.map(i => i.value),
    )
  }, [listItems])

  return (
    <FormField label={label} error={form.errors[field.name] as string}>
      <Element renderAs="ul" mb={3}>
        <List
          values={listItems}
          onChange={({ oldIndex, newIndex }) => moveItemInList(oldIndex, newIndex)}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props, isDragged }) => (
            <div {...props}>
              <Element p={1} py={2} backgroundColor={isDragged ? 'light' : undefined}>
                <Button
                  color="primary"
                  outlined
                  type="button"
                  onClick={() => removeItemFromList(value.value)}
                  size="small"
                  data-tooltip="Supprimer de la liste"
                  style={{ marginTop: -3 }}
                  mr={3}
                >
                  <Icon>
                    <RemoveIcon />
                  </Icon>
                </Button>
                <Icon.Text style={{ cursor: isDragged ? 'grabbing' : 'grab' }}>
                  <Icon
                    data-tooltip="Réordonnez la liste par glisser-déposer"
                    color="grey-light"
                    style={{ cursor: isDragged ? 'grabbing' : 'grab' }}
                  >
                    <DraggableListIcon />
                  </Icon>
                  <Element renderAs="span" ml={2}>
                    {value.label}
                  </Element>
                </Icon.Text>
              </Element>
            </div>
          )}
        />
      </Element>
      <Formik initialValues={{ selected: [] }} onSubmit={() => undefined}>
        {props => (
          <>
            <FormikObserver
              values={props.values}
              onChange={values => {
                const value = _get(values, name)
                if (value) addItemToList(value)
              }}
            />
            <Field
              component={AutocompleteField}
              items={selectableItems}
              onlySelect
              name={name}
              clearOnSelect
              showItemsAfterSelect
              placeholder="Choisir..."
            />
          </>
        )}
      </Formik>
    </FormField>
  )
}

export default ListField
