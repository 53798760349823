import { sortBy } from 'lodash'
import { Mission } from '../api/missions'
import React from 'react'

//Start: the date of start of earliest work period
//End: the date of start of lastest work period
const useMissionsStartAndEndDates = (
  missions: Mission[],
): { start: Date | undefined; end: Date | undefined } =>
  React.useMemo(() => {
    const firstMission = sortBy(missions, 'start')[0]
    const lastMission = sortBy(missions, 'end').reverse()[0]
    const lastWorkPeriod = lastMission?.workPeriods[lastMission?.workPeriods.length - 1]
    return {
      start: firstMission?.start || new Date(),
      end: lastWorkPeriod?.start.date || new Date(), //Get the last day is the day of work period start (a mission can be start a day and end the day after)
    }
  }, [missions])

export default useMissionsStartAndEndDates
