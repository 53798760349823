import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns'
import { Organization } from '../api/organizations'
import { User, UserAssociation, UserRole, UserRoleKind } from '../api/users'
import useStore from '../store'
import { localDate } from './date'
import { compareDocsById } from './documents'

interface UserLabelData {
  firstName: string
  lastName: string
  appAccess: User['appAccess']
  internalRef?: string
  associations?: User['associations']
}

export const getUserLabel = (
  user: UserLabelData,
  options?: { hideOfflineInfo?: boolean },
): string => {
  const currentOrganization = useStore.getState().session.currentOrganization
  const internalRef =
    user.internalRef ||
    (user.associations &&
      getWorkerAndOrganizationInternalRef({ associations: user.associations }, currentOrganization))

  const offlineStatus = user.appAccess === 'none' && !options?.hideOfflineInfo ? ' - offline' : ''
  let label = `${user.firstName} ${user.lastName}${offlineStatus}`
  if (internalRef) label += ` (${internalRef})`
  return label
}

export const getWorkerAndOrganizationAssociation = (
  worker: Pick<User, 'associations'>,
  organization: Organization,
): UserAssociation | undefined =>
  worker.associations.find(association => compareDocsById(association.organization, organization))

export const getUserRole = (
  user: User,
  roleKind: UserRoleKind,
  organizationId?: string,
): UserRole | undefined => {
  if ((roleKind === 'employerMember' || roleKind === 'interimAgencyMember') && !organizationId)
    return
  return user.roles.find(
    role =>
      role.kind === roleKind &&
      (!organizationId || compareDocsById(organizationId, role.organization)),
  )
}

export const getWorkerAndOrganizationInternalRef = (
  worker: Pick<User, 'associations'>,
  organization?: Organization,
): string | undefined => {
  const association = organization && getWorkerAndOrganizationAssociation(worker, organization)
  return association?.internalRef || ''
}

const getAssociationsForWhichWorkerIsNotAvailable = (
  associations: UserAssociation[],
): UserAssociation[] =>
  associations.filter(association => {
    const { absentFrom, absentTo } = association.availability

    return (
      absentFrom &&
      isAfter(new Date(), new Date(absentFrom)) &&
      (!absentTo || isBefore(new Date(), new Date(absentTo)))
    )
  })

export const getWorkerAvailabilityForOrganization = (
  user: Pick<User, 'associations'>,
  organization: Organization,
): UserAssociation['availability'] | undefined => {
  const association = getWorkerAndOrganizationAssociation(user, organization)
  return association?.availability
}

const checkWorkerIsAvailableForOrganizationInPeriod = (
  start: Date,
  end: Date,
  user: Pick<User, 'associations'>,
  organization: Organization,
): boolean => {
  const availability = getWorkerAvailabilityForOrganization(user, organization)
  return Boolean(!availability || checkAvailibilityInPeriod(start, end, availability))
}

export const checkAvailibilityInPeriod = (
  start: Date,
  end: Date,
  availability: UserAssociation['availability'],
): boolean => {
  const { absentFrom } = availability || {}
  let { absentTo } = availability || {}
  if (!absentFrom) return true
  if (!absentTo) absentTo = new Date(3000, 1, 1)

  if (isBefore(new Date(absentFrom), end) && isAfter(new Date(absentTo), start)) return false

  return true
}

export const workerIsIsAvailableForOrganizationAndDay = (
  date: Date,
  user: Pick<User, 'associations'>,
  organization: Organization,
): boolean =>
  checkWorkerIsAvailableForOrganizationInPeriod(
    startOfDay(date),
    endOfDay(date),
    user,
    organization,
  )

export const getWorkerAbsentLabelForOrganization = (
  user: User,
  organization: Organization,
): string => {
  const { absentFrom, absentTo } = getWorkerAvailabilityForOrganization(user, organization) || {}
  let label = `${getUserLabel(user)} s'est renseigné comme indisponible du 
  ${absentFrom && localDate(absentFrom)}`
  if (absentTo) label = `${label} au ${localDate(absentTo)}`
  else label = `${label} et pour une durée indeterminée`
  return label
}

export const getWorkerAbsentLabel = (
  availability: UserAssociation['availability'],
  user: UserLabelData,
  short?: boolean,
): string => {
  const { absentFrom, absentTo } = availability
  if (!absentFrom) return ''
  let label = short
    ? `Du ${absentFrom && localDate(absentFrom)}`
    : `${getUserLabel(user)} s'est renseigné comme indisponible du 
  ${absentFrom && localDate(absentFrom)}`
  if (absentTo) label = `${label} au ${localDate(absentTo)}`
  else label = short ? `${label} au indeterminé` : `${label} et pour une durée indeterminée`
  return label
}

export const getWorkerRoleOrAssociationStatus = (
  user: User,
  roleKind: UserRoleKind,
  organization?: Organization,
): UserRole['status'] | undefined => {
  if (roleKind === 'worker' && organization) {
    const association = getWorkerAndOrganizationAssociation(user, organization)
    return association?.status
  }
  return getUserRole(user, roleKind, organization?._id)?.status
}
