import { Field, Form, Formik, FormikErrors, useFormikContext } from 'formik'
import React, { useMemo } from 'react'
import { Box, Columns, Container, Element, Heading, Icon, Message } from 'react-bulma-components'
import { useCreateMutation, useUpdateMutation } from '../../queries/clocking-rules'
import InputField from '../form/fields/input'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { TimingRuleData } from '../../../../backend/src/services/resources/clocking-rules/clocking-rule.model'
import SelectField from '../form/fields/select'
import { buildListAsInputOptions } from '../../utils/forms'
import { ClockingRules, ClockingRulesCreationPayload } from '../../api/clocking-rules'
import {
  buildInitialValues,
  convertClockingRulesTolerances,
  maxTolerance,
  minTolerance,
  toleranceInMinutesToMilliseconds,
} from '../../utils/clocking-rules'
import { WarningIcon } from '../icons'
import { dateInputToDateValue } from '../../utils/date'
import CheckboxField from '../form/fields/checkbox'
import ClockingsRulesClockedBreaksDescription from './clockings-rules-clocked-breaks-description'

interface ClockingRulesFormProps {
  clockingRules?: ClockingRules
}

const ClockingsRulesForm: React.FunctionComponent<ClockingRulesFormProps> = ({ clockingRules }) => {
  const isUpdateForm = clockingRules && clockingRules._id

  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const currentMutation = isUpdateForm ? updateMutation : createMutation
  const initialValues = useMemo(() => buildInitialValues(clockingRules), [clockingRules?._id])

  return (
    <Formik<ClockingRulesCreationPayload & { updateExistingMissionsFrom?: string }>
      initialValues={initialValues}
      enableReinitialize
      validate={values => {
        const errors: FormikErrors<ClockingRulesCreationPayload> = {}
        // Name field is required
        if (!values.name) errors.name = 'Champ requis'

        // Tolerance has to be between a Min and Max if ruleMode is set to 'clocking'
        const rules = values.rules
        const toleranceRuleError = `Tolérance doit être entre ${minTolerance} et ${maxTolerance} minutes`
        const toleranceValidation = (
          startOrEnd: keyof ClockingRules['rules'],
          earlyOrLate: keyof ClockingRules['rules']['start'] | keyof ClockingRules['rules']['end'],
        ) => {
          const rule = rules[startOrEnd][earlyOrLate]
          if (
            rule.mode === 'clocking' &&
            (rule.tolerance < minTolerance || rule.tolerance > maxTolerance)
          ) {
            // No typing here :-()
            errors[`rules.${startOrEnd}.${earlyOrLate}.tolerance`] = toleranceRuleError
          }
        }
        toleranceValidation('start', 'early')
        toleranceValidation('start', 'late')
        toleranceValidation('end', 'early')
        toleranceValidation('end', 'late')

        return errors
      }}
      onSubmit={values => {
        const converted = convertClockingRulesTolerances(values, toleranceInMinutesToMilliseconds)
        if (!isUpdateForm) createMutation.mutate(converted)
        else
          updateMutation.mutate({
            _id: clockingRules._id,
            ...converted,
            updateExistingMissionsFrom: values.updateExistingMissionsFrom
              ? dateInputToDateValue(values.updateExistingMissionsFrom)
              : undefined,
          })
      }}
    >
      {formik => {
        const breaksAreClocked = formik.values.break.clocked
        return (
          <Form>
            <Columns>
              <Columns.Column size={12}>
                <Columns mb={5}>
                  <Columns.Column>
                    <Field label="Nom" name="name" component={InputField} required />
                  </Columns.Column>
                  <Columns.Column>
                    <Field label="Description" name="description" component={InputField} />
                  </Columns.Column>
                </Columns>

                <Heading size={4}>Début de Journée</Heading>
                <Box>
                  <Columns>
                    <Columns.Column>
                      <ClockingRuleField startOrEnd={'start'} earlyOrLate={'early'} />
                    </Columns.Column>
                    <Columns.Column>
                      <ClockingRuleField startOrEnd={'start'} earlyOrLate={'late'} />
                    </Columns.Column>
                  </Columns>
                </Box>
                <Heading size={4}>Fin de Journée</Heading>
                <Box>
                  <Columns>
                    <Columns.Column>
                      <ClockingRuleField startOrEnd={'end'} earlyOrLate={'early'} />
                    </Columns.Column>
                    <Columns.Column>
                      <ClockingRuleField startOrEnd={'end'} earlyOrLate={'late'} />
                    </Columns.Column>
                  </Columns>
                </Box>
                <Heading size={4}>Pause</Heading>
                <Box>
                  <Columns>
                    <Columns.Column size={12}>
                      <Field
                        name="break.clocked"
                        component={CheckboxField}
                        label="Les pauses sont pointées"
                      />
                    </Columns.Column>
                    <Columns.Column size={6}>
                      <Field
                        name="break.breakDuration"
                        component={InputField}
                        type="number"
                        min={0}
                        max={4 * 60}
                        label={
                          breaksAreClocked ? (
                            <>
                              {' '}
                              <br />
                              La durée de pause théorique est de
                            </>
                          ) : (
                            'La durée de pause est de'
                          )
                        }
                        help="Spécifier une valeur en minutes"
                      />
                    </Columns.Column>
                    <Columns.Column size={6}>
                      <Field
                        name="break.breakAllowedAfter"
                        component={InputField}
                        disabled={formik.values.break.breakDuration === 0}
                        type="number"
                        min={0}
                        max={12 * 60}
                        label={
                          breaksAreClocked
                            ? 'Si pas de pointage de pauses, attribuer la pause automatiquement après'
                            : 'après une durée de prestation de minimum'
                        }
                        help="Spécifier une valeur en minutes"
                      />
                    </Columns.Column>
                    {breaksAreClocked && (
                      <>
                        <Columns.Column size={6}>
                          <Field
                            name="break.clockingsTolerance.early"
                            component={InputField}
                            disabled={formik.values.break.breakDuration === 0}
                            type="number"
                            min={0}
                            max={12 * 60}
                            label={
                              'Attribuer le temps de pause théorique si la pause pointée est plus courte de'
                            }
                            help="Spécifier une valeur en minutes"
                          />
                        </Columns.Column>
                        <Columns.Column size={6}>
                          <Field
                            name="break.clockingsTolerance.late"
                            component={InputField}
                            disabled={formik.values.break.breakDuration === 0}
                            type="number"
                            min={0}
                            max={12 * 60}
                            label={
                              'Attribuer le temps de pause théorique si la pause pointée est plus longue de'
                            }
                            help="Spécifier une valeur en minutes"
                          />
                        </Columns.Column>
                        <Columns.Column size={12}>
                          <ClockingsRulesClockedBreaksDescription rules={formik.values.break} />
                        </Columns.Column>
                      </>
                    )}
                  </Columns>
                </Box>
                {clockingRules /*&& !isEqual(formik.values, initialValues)*/ && (
                  <>
                    <Heading size={4} mt={5}>
                      <Icon.Text>
                        <Icon mr={1}>
                          <WarningIcon color="red" />
                        </Icon>
                        Modification des journées de travail en cours{' '}
                      </Icon.Text>
                    </Heading>
                    <Box>
                      <Message color="warning" p={2}>
                        Vous pouvez modifier la règle de pointage des journées de travails qui
                        utilisent la règle de pointage{' '}
                        <Element renderAs="strong">{formik.values.name}</Element>.
                        <br /> Uniquement les journées de travails qui ne sont{' '}
                        <Element renderAs="strong">pas validées</Element> et qui commence à partir
                        de <Element renderAs="strong">la date sélectionnée</Element> seront
                        modifiées.
                        <br />
                        <br />
                        Si aucune date n'est sélectionnée la modification de la règle de pointage
                        sera attribué qu'aux nouvelles missions. Les missions et journées de travail
                        en cours utiliseront toujours l'ancienne règle.
                      </Message>

                      <Field
                        name="updateExistingMissionsFrom"
                        component={InputField}
                        type="date"
                        label="Modifier les journées de travail à partir du"
                        help="Si aucune date n'est choisie, aucune journée de travail ne sera modifiée"
                      />
                    </Box>
                  </>
                )}
              </Columns.Column>
            </Columns>
            <Container>
              <Element mt={4}>
                <Element mb={1}>
                  <RequestMessage mutation={currentMutation} />
                </Element>
                <RequestButton color="primary" type="submit" mutation={currentMutation}>
                  {clockingRules
                    ? 'Mettre à jour les Règles de Pointage'
                    : 'Créer des Nouvelles Règles de Pointage'}
                </RequestButton>
              </Element>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ClockingsRulesForm

interface ClockingRuleFieldProps {
  startOrEnd: keyof ClockingRules['rules']
  earlyOrLate: keyof ClockingRules['rules']['start'] | keyof ClockingRules['rules']['start']
}

const ClockingRuleField: React.FC<ClockingRuleFieldProps> = ({ startOrEnd, earlyOrLate }) => {
  const { values } = useFormikContext<ClockingRulesCreationPayload>()

  const startOrEndLabel = {
    start: 'Début',
    end: 'Fin',
  }
  const earlyOrLateLabels = {
    early: 'avance',
    late: 'retard',
  }
  const options = (
    startOrEnd: ClockingRuleFieldProps['startOrEnd'],
  ): { name: string; _id: TimingRuleData['mode'] }[] => [
    { name: `L'heure de ${startOrEndLabel[startOrEnd]} de Journée`, _id: 'theoritical' },
    { name: 'Le Pointage', _id: 'clocking' },
  ]
  return (
    <>
      <Heading size={5}>Je pointe en {earlyOrLateLabels[earlyOrLate]}</Heading>
      <Field
        label={
          'On tient compte de : ' +
          buildListAsInputOptions(options(startOrEnd)).find(
            option => option.value === values.rules[startOrEnd][earlyOrLate].mode,
          )?.label
        }
        name={`rules.${startOrEnd}.${earlyOrLate}.mode`}
        component={SelectField}
        options={buildListAsInputOptions(options(startOrEnd))}
        type="number"
        required
      />
      {values['rules'][startOrEnd][earlyOrLate].mode === 'clocking' && (
        <Field
          label={`Sauf si ${earlyOrLateLabels[earlyOrLate]} de moins de ${values.rules[startOrEnd][earlyOrLate].tolerance} minutes`}
          name={`rules.${startOrEnd}.${earlyOrLate}.tolerance`}
          component={InputField}
          type="number"
          required
          min={minTolerance}
          max={maxTolerance}
          step={1}
          help={`Tolérance : de ${minTolerance} à ${maxTolerance} minutes`}
        />
      )}
    </>
  )
}
