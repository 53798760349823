import { UserCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import { Element, Icon } from 'react-bulma-components'
import Avatar from '../avatar/avatar'

interface QuoteProps {
  message: string
  sender?: {
    firstName: string
    lastName: string
  }
}

const Quote: React.FC<QuoteProps> = ({ message, sender }) => {
  return (
    <Element>
      <Element
        renderAs="blockquote"
        backgroundColor="light"
        p={3}
        pl={4}
        style={{ borderLeft: '4px solid #dbdbdb' }} //TODO: css?
      >
        {message}
      </Element>
      {sender && (
        <Element textAlign="right">
          <i>
            <Icon size="small" style={{ verticalAlign: 'middle' }} mr={1}>
              <UserCircleIcon />
            </Icon>
            <Avatar firstName={sender.firstName} lastName={sender.lastName} />
          </i>
        </Element>
      )}
    </Element>
  )
}

export default Quote
