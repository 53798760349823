import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { queryClient, UseMutationResult, useResources } from '.'
import {
  Clocking,
  ClockingCreationPayload,
  create,
  fetch,
  FetchClockingsParams,
} from '../api/clockings'

//Queries
export const useClockingsQuery = (
  filters?: FetchClockingsParams,
  options?: { refetchInterval?: number; enabled?: boolean },
): UseQueryResult<Clocking[], FetchClockingsParams> =>
  useResources('clockings', fetch, filters || {}, options)

//Mutations
const useCreateMutationKey = (): QueryKey => ['clockings', 'create']
export const useCreateMutation = (): UseMutationResult<Clocking, ClockingCreationPayload, any> =>
  useMutation(create, {
    mutationKey: useCreateMutationKey(),
    onSuccess: () => {
      queryClient.invalidateQueries(['clockings'])
    },
  })
