import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { UseMutationResult, useResources } from '.'
import { Mission } from '../api/missions'
import {
  fetch,
  update,
  updateMany,
  WorkPeriodManyUpdatePayload,
  WorkPeriodUpdatePayload,
  WorkPeriodWithMission,
} from '../api/work-periods'
import { invalidateMissionsQueries } from './missions'

//Queries
export const useWorkPeriodsQuery = (filters?: any): UseQueryResult<WorkPeriodWithMission[], any> =>
  useResources<WorkPeriodWithMission[], any>('work-periods', fetch, filters)

//Mutations
const useUpdateMutationKey = (): QueryKey => ['missions', 'work-periods', 'update']
export const useUpdateMutation = (): UseMutationResult<Mission, WorkPeriodUpdatePayload, any> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: () => {
      invalidateMissionsQueries()
    },
  })

export const useUpdateManyMutationKey = (): QueryKey => [
  'missions',
  'work-periods',
  'many',
  'update',
]
export const useUpdateManyMutation = (): UseMutationResult<
  Mission,
  WorkPeriodManyUpdatePayload,
  any
> =>
  useMutation(updateMany, {
    mutationKey: useUpdateManyMutationKey(),
    onSuccess: () => {
      invalidateMissionsQueries()
    },
  })
