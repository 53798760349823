import React from 'react'
import { shallow } from 'zustand/shallow'
import Notification from '../components/notifications/notification'
import { useIsAuthQuery } from '../queries/users'
import useRoute from '../hooks/use-route'
import NetworkError from '../pages/errors/network'
import useStore from '../store'

const App: React.FunctionComponent = () => {
  const [sessionIsInitialized, isNetworkOnError] = useStore(
    state => [state.session.isInitialized, state.session.isNetworkOnError],
    shallow,
  )

  // Initialize & verify session via API and update session data in zustand store
  useIsAuthQuery()

  // Get route by pathname
  const route = useRoute()

  // Does not render when session is not initialized
  // Session is initialized by isAuth query
  if (!sessionIsInitialized) return <>{isNetworkOnError && <NetworkError />}</>

  return (
    <>
      {isNetworkOnError && <Notification type="danger">Erreur réseau</Notification>}
      {route}
    </>
  )
}

export default App
