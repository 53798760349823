import { QueryKey, useMutation, UseQueryResult } from '@tanstack/react-query'
import { MissionsFilters, FiltersResourcesEnum } from '../store/filters'
import { WorkPeriodWithMissionAndSummary, WorkPeriodWithSummary } from '../api/work-periods'
import useStore from '../store'
import { sortBy } from 'lodash'
import {
  fetch,
  fetchServices,
  create,
  update,
  MissionUpdatePayload,
  Mission,
  MissionCreationPayload,
  MissionWithSummary,
  get,
  exportSummary,
  fetchSummary,
  SummaryFilters,
  ExportSummaryParams,
  WeeksSummaryParams,
  fetchWeeksSummary,
  WeeksSummary,
  count,
} from '../api/missions'
import { queryClient, QueryOptions, UseMutationResult, useResource, useResources } from '.'
import { UserWeekSummary } from '../../../backend/src/services/resources/missions/missions.summary.service.types'

//Queries
export const useMissionQuery = (id?: string): UseQueryResult<MissionWithSummary, any> =>
  useResource('missions', get, id)

export const useMissionsCountQuery = (
  filters?: MissionsFilters,
  options?: QueryOptions,
): UseQueryResult<number, any> =>
  useResources<number, MissionsFilters>(
    'missions/count',
    count,
    filters || useStore(state => state.filters[FiltersResourcesEnum.missions]),
    options,
  )

export const useMissionsQuery = (
  filters?: MissionsFilters & { unSelectedFields: string[] },
  options?: QueryOptions,
): UseQueryResult<MissionWithSummary[], any> =>
  useResources<MissionWithSummary[], MissionsFilters>(
    'missions',
    fetch,
    filters || useStore(state => state.filters[FiltersResourcesEnum.missions]),
    options,
  )

export const useSummaryQuery = (
  filters?: SummaryFilters,
  options?: QueryOptions,
): UseQueryResult<UserWeekSummary[], any> => {
  const { summaryMinimalMode, displayDay, ...params } =
    filters || useStore(state => state.filters[FiltersResourcesEnum.missions])
  return useResources<UserWeekSummary[], MissionsFilters>(
    'missions/summary',
    fetchSummary,
    params,
    options,
  )
}

export const useWeeksSummaryQuery = (
  filters: WeeksSummaryParams,
  options?: QueryOptions,
): UseQueryResult<WeeksSummary, any> =>
  useResources<WeeksSummary, WeeksSummaryParams>(
    'missions/weeks-summary',
    fetchWeeksSummary,
    filters,
    options,
  )

export const useWorkPeriodsQuery = (
  filters?: MissionsFilters & { populate?: string[]; summary?: boolean | null; fields?: string[] },
): UseQueryResult<WorkPeriodWithMissionAndSummary[], any> =>
  //TODO: use /work-periods (remove this query)
  useResources<WorkPeriodWithSummary[], MissionsFilters, Mission[]>(
    'missions/work-periods',
    fetch,
    filters || useStore(state => state.filters[FiltersResourcesEnum.missions]),
    {
      select(data): WorkPeriodWithSummary[] {
        const workPeriods: WorkPeriodWithSummary[] = []
        const missions = data.data
        missions.forEach(mission => {
          Array.prototype.push.apply(
            workPeriods,
            mission.workPeriods.map(workPeriod => ({
              ...workPeriod,
              mission,
            })),
          )
        })
        return sortBy(workPeriods, 'start.date')
      },
    },
  )

const useMissionsServicesQuery = (
  filters?: MissionsFilters,
  options?: QueryOptions, // TODO: any?!
): UseQueryResult<string[], any> =>
  useResources<string[], MissionsFilters>(
    'missions/services',
    fetchServices,
    filters || useStore(state => state.filters[FiltersResourcesEnum.missions]),
    options,
  )

//Mutations
const useCreateMutationKey = (): QueryKey => ['missions', 'create']
export const useCreateMutation = (): UseMutationResult<Mission, MissionCreationPayload, any> =>
  useMutation(create, {
    mutationKey: useCreateMutationKey(),
    onSuccess: () => {
      invalidateMissionsQueries()
    },
  })

export const useUpdateMutationKey = (): QueryKey => ['missions', 'update']
export const useUpdateMutation = (): UseMutationResult<Mission, MissionUpdatePayload, any> =>
  useMutation(update, {
    mutationKey: useUpdateMutationKey(),
    onSuccess: () => {
      invalidateMissionsQueries()
    },
  })

const useExportSummaryMutationKey = (): QueryKey => ['missions', 'csv']
export const useExportSummaryMutation = (): UseMutationResult<string, ExportSummaryParams, any> =>
  useMutation(exportSummary, {
    mutationKey: useExportSummaryMutationKey(),
  })

//Methods

export const invalidateMissionsQueries = () => {
  queryClient.invalidateQueries(['missions'])
  queryClient.invalidateQueries(['missions/summary'])
}
