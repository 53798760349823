import React, { useEffect } from 'react'
import { FieldProps } from 'formik'
import { Form, Icon } from 'react-bulma-components'
import FormField, { FormFieldProps } from './form-field'
import { useCombobox } from 'downshift'
import { get } from 'lodash'
import { SearchIcon } from '../../icons'

export interface AutocompleteFieldItem {
  value: string
  label: string
  renderedLabel?: React.ReactNode
  [key: string]: any
}

interface AutocompleteFieldProps extends FormFieldProps, FieldProps {
  type: string
  items: AutocompleteFieldItem[]
  onlySelect?: boolean
  disabled?: boolean
  placeholder?: string
  clearOnSelect?: boolean
  showItemsAfterSelect?: boolean
}

const AutocompleteField: React.FunctionComponent<AutocompleteFieldProps> = ({
  field,
  form,
  items,
  label,
  disabled = false,
  onlySelect,
  placeholder,
  clearOnSelect,
  showItemsAfterSelect,
}) => {
  const [activeItems, setActiveItems] = React.useState(items)
  const [currentInputValue, setCurrentInputValue] = React.useState<string | undefined>()

  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    setActiveItems(filterItems(items, currentInputValue))
  }, [items])

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    reset,
    highlightedIndex,
    setInputValue,
  } = useCombobox<AutocompleteFieldItem>({
    items: activeItems,
    defaultInputValue: get(form.initialValues, field.name),
    onSelectedItemChange: changes => {
      form.setFieldValue(
        field.name,
        changes.selectedItem?.value || (!onlySelect && changes.inputValue),
      )
      if (clearOnSelect) {
        setInputValue('')
      }
      if (showItemsAfterSelect) {
        inputRef?.current?.blur()
        inputRef?.current?.focus()
      }
    },
    itemToString: item => (item ? item.label : ''),
    onInputValueChange({ inputValue }) {
      !onlySelect && form.setFieldValue(field.name, inputValue)
      setCurrentInputValue(inputValue)
      setActiveItems(filterItems(items, inputValue))
    },
  })

  const inputProps = {
    className: 'input',
    onFocus: () => !isOpen && items[0] && openMenu(),
  }

  useEffect(() => {
    reset()
  }, [form.initialValues])

  return (
    <FormField label={label} error={form.errors[field.name] as string}>
      <div {...getComboboxProps()}>
        <Form.Field>
          <Form.Control>
            <input
              {...getInputProps({ ...inputProps, disabled, ref: inputRef })}
              placeholder={placeholder}
              name={field.name}
              data-lpignore="true"
              className="input"
            />
            <Icon align="left" p={2}>
              <SearchIcon />
            </Icon>
          </Form.Control>
        </Form.Field>
      </div>
      <div {...getMenuProps()}>
        {isOpen && (
          <ul
            style={{
              maxHeight: `250px`,
              overflow: `scroll`,
              position: `absolute`,
              zIndex: 10,
              width: `100%`,
            }}
            className="filter-dropdown"
          >
            {activeItems.map((item, index) => (
              <li
                key={`${item.value}-${item.field}-${item.count}`}
                {...getItemProps({
                  item,
                  index,
                  className: [
                    `py-2 has-text-left pl-2 pr-1`,
                    highlightedIndex === index ? `has-background-primary` : `has-background-white`,
                  ].join(' '),
                })}
              >
                {item.renderedLabel || item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </FormField>
  )
}

const filterItems = (items: AutocompleteFieldItem[], inputValue: string | undefined) =>
  items.filter(
    item =>
      !inputValue || (inputValue && item.label.toLowerCase().includes(inputValue.toLowerCase())),
  )

export default AutocompleteField
