import React from 'react'
import { Element } from 'react-bulma-components'
import { WorkPeriodWithMission } from '../../../api/work-periods'
import { useUpdateMutation } from '../../../queries/work-periods'
import { formatCompleteDate } from '../../../utils/date'
import Avatar from '../../avatar/avatar'
import ConfirmationModal, {
  ConfirmationModalBaseProps,
} from '../../confirmation-modal/confirmation-modal'

interface WorkPeriodValidateConfirmationProps extends ConfirmationModalBaseProps {
  workPeriod: WorkPeriodWithMission
  hide: () => void
}

const WorkPeriodValidateConfirmation: React.FunctionComponent<WorkPeriodValidateConfirmationProps> =
  ({ workPeriod, hide }) => {
    const mutation = useUpdateMutation()

    const { canBeValidated } = workPeriod.__actions

    const onConfirm = async () => {
      await mutation.mutateAsync({
        missionId: workPeriod.mission._id,
        workPeriodId: workPeriod._id,
        isValidated: !workPeriod.isValidated,
      })
      hide()
    }

    return (
      <ConfirmationModal show={Boolean(workPeriod)} onConfirm={onConfirm} onCancel={hide}>
        <Element>
          {canBeValidated
            ? 'Valider définitivement la journée de travail du '
            : 'Déverrouiller la journée de travail précédemment validée du '}
          <Element renderAs="strong">{formatCompleteDate(workPeriod.start.date)}</Element> de{' '}
          <Element renderAs="strong">
            <Avatar {...workPeriod.mission.user} />
          </Element>
          ?
        </Element>
      </ConfirmationModal>
    )
  }

export default WorkPeriodValidateConfirmation
