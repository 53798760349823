import React from 'react'
import { Column } from 'react-table'
import Table from '../table'
import { eachDayOfInterval, format, isSameDay, setYear } from 'date-fns'
import {
  endOfWeek,
  formatDayName,
  formatTime,
  setWeek,
  startOfWeek,
  formatDuration,
} from '../../utils/date'
import { WorkPeriodWithSummary } from '../../api/work-periods'
import { Element } from 'react-bulma-components'
import { sortBy } from 'lodash'
import DescriptionList from '../description-list/description-list'
import {
  WorkPeriodAbandonedMessage,
  WorkPeriodCancelledMessage,
  WorkPeriodEventsMessage,
  WorkPeriodNotValidatedMessage,
  WorkPeriodValidatedMessage,
} from '../work-periods/work-period-messages'
import { UserSummaries } from '../../api/missions'
import { WorkPeriodEvent } from '../../api/work-period-events'
import { buildWorkPeriodEventLabel } from '../../utils/work-period-events'

interface PayrollWeekTableProps {
  weekSummary?: UserSummaries['weeks'][0]
  weekNumber: number
  year: number
  weeklyHoursWorked?: number
  events: WorkPeriodEvent[]
}

interface ColumnData {
  messages?: React.ReactNode
  dayName: React.ReactNode
  date?: string | null
  theoriticalStart?: string | null
  theoriticalEnd?: string | null
  countedStart?: string | null
  countedEnd?: string | null
  theoreticalDuration?: React.ReactNode | null
  countedDuration?: React.ReactNode | null
  countedBreak?: string | null
  events?: React.ReactNode
  overtime?: React.ReactNode | null
  contractualOvertime?: React.ReactNode | null
  basket?: number
  rc?: React.ReactNode
  [key: string]: React.ReactNode | null
}

const PayrollWeekTable: React.FC<PayrollWeekTableProps> = ({
  weekSummary,
  weekNumber,
  year,
  weeklyHoursWorked,
  events,
}) => {
  const tableColumns = useColumns(events)
  const weekStartDay = startOfWeek(setWeek(setYear(new Date(), year), weekNumber))
  const weekDays = eachDayOfInterval({ start: weekStartDay, end: endOfWeek(weekStartDay) })

  const data = weekDays.reduce<ColumnData[]>((tableData, day) => {
    const allWorkPeriods = sortBy(
      weekSummary?.missions.flatMap(m => m.workPeriods) || [],
      'start.date',
    )

    const workPeriodOfDay = allWorkPeriods.filter(w => isSameDay(new Date(w.start.date), day))

    if (!workPeriodOfDay[0]) tableData.push(initWorkPeriodRowData(day))
    else if (workPeriodOfDay[0]) {
      workPeriodOfDay.forEach(workPeriod =>
        tableData.push(buildWorkPeriodRowData(workPeriod, day, events)),
      )
    }

    return tableData
  }, [])

  data.push({
    dayName: <strong>TOTAL</strong>,
    date: `Contrat ${weeklyHoursWorked}h`,
    theoreticalDuration: weekSummary?.summary.durations.worked.theoretical
      ? formatDuration(weekSummary?.summary.durations.worked.theoretical)
      : undefined,
    countedDuration: (
      <WeekWorkedDurationCell weekSummary={weekSummary} weeklyHoursWorked={weeklyHoursWorked} />
    ),
    overtime: weekSummary?.summary.durations.overtime.total
      ? formatDuration(weekSummary?.summary.durations.overtime.total)
      : '0',
    basket: weekSummary?.summary.basket,
    rc: weekSummary?.summary.durations.rc
      ? formatDuration(weekSummary?.summary.durations.rc)
      : undefined,
    countedBreak: weekSummary?.summary.durations.breaks
      ? formatDuration(weekSummary?.summary.durations.breaks)
      : undefined,
    contractualOvertime: weekSummary?.summary.durations.overtime.contractual ? (
      <DurationDiff duration={weekSummary?.summary.durations.overtime.contractual} />
    ) : undefined,
  })

  return (
    <>
      <Table columns={tableColumns} data={data} noDataMessage="..." />
      {weekSummary && (
        <Element style={{ marginTop: -32 }}>
          <DescriptionList
            labelColumnWidth={560}
            items={[
              {
                label: 'Heures Contractuelles de Base à prester sur la semaine  / Ecart',
                value: (
                  <>
                    {formatDuration(weekSummary.summary.durations.contract.legal || 0)} /{' '}
                    <DurationDiff
                      duration={weekSummary.summary.durations.worked.contract.legalDiff}
                      showZero
                    />
                  </>
                ),
              },
              {
                label: 'Heures Contractuelles Supplémentaires à prester sur la période  / Ecart',
                value: (
                  <>
                    {formatDuration(weekSummary.summary.durations.contract.extraLegal || 0)} /{' '}
                    <DurationDiff
                      duration={weekSummary.summary.durations.worked.contract.extraLegalDiff}
                      showZero
                    />
                  </>
                ),
              },
              {
                label: 'Heures Supplémentaires Non-Contractuelles ',
                value: (
                  <DurationDiff
                    duration={weekSummary.summary.durations.overtime.nonContractual || undefined}
                    showZero
                  />
                ),
              },
            ]}
          />
        </Element>
      )}
    </>
  )
}

export default PayrollWeekTable

const buildWorkPeriodRowData = (
  workPeriod: WorkPeriodWithSummary,
  day: Date,
  events: WorkPeriodEvent[],
): ColumnData => {
  const countedStart =
    workPeriod?.summary.start.counted &&
    !workPeriod.summary.isBenefitWorkPeriod &&
    workPeriod.summary.start[workPeriod?.summary.start.counted]
  const countedEnd =
    workPeriod?.summary.end.counted &&
    !workPeriod.summary.isBenefitWorkPeriod &&
    workPeriod.summary.end[workPeriod?.summary.end.counted]

  const benefitEvents = events
    .filter(e => e.type === 'benefit')
    .reduce<{ [key: string]: React.ReactNode }>((events, event) => {
      events[`event_${event.code}`] = workPeriod.events.some(e => e.item._id === event._id) ? (
        <Element data-tooltip={buildWorkPeriodEventLabel(event)}>1</Element>
      ) : (
        ''
      )
      return events
    }, {})
  const informativeEvents = workPeriod.events.filter(e => e.item.type === 'informative')

  return {
    ...initWorkPeriodRowData(day),
    messages: (
      <>
        <WorkPeriodValidatedMessage workPeriod={workPeriod} small type="icon" />
        <WorkPeriodNotValidatedMessage workPeriod={workPeriod} small type="icon" />
        <WorkPeriodCancelledMessage workPeriod={workPeriod} small type="icon" />
        <WorkPeriodAbandonedMessage workPeriod={workPeriod} small type="icon" />
      </>
    ),
    countedStart: countedStart ? formatTime(countedStart) : '',
    countedEnd: countedEnd ? formatTime(countedEnd) : '',
    countedDuration:
      (workPeriod.summary.durations?.worked.total &&
        formatDuration(workPeriod.summary.durations.worked.total)) ||
      undefined,
    countedBreak: workPeriod.summary.durations.breaks
      ? formatDuration(workPeriod.summary.durations.breaks)
      : undefined,
    theoreticalDuration: formatDuration(workPeriod.summary.durations.worked.theoretical),
    theoriticalStart: formatTime(workPeriod.start.date),
    theoriticalEnd: formatTime(workPeriod.end.date),
    overtime:
      workPeriod.summary.durations.diff && workPeriod.summary.durations.diff >= 0 ? (
        <DurationDiff duration={workPeriod.summary.durations.diff} />
      ) : undefined,
    basket: workPeriod.summary.basket,
    events: informativeEvents[0] && (
      <WorkPeriodEventsMessage small type="icon" workPeriod={{ events: informativeEvents }} />
    ),
    ...benefitEvents,
  }
}

const initWorkPeriodRowData = (day: Date): ColumnData => {
  return {
    dayName: formatDayName(day),
    date: format(day, 'dd MMMM'),
  }
}

const useColumns = (events: WorkPeriodEvent[]): Column<ColumnData>[] => {
  const benefitEvents = events.filter(e => e.type === 'benefit')

  return React.useMemo(
    () => [
      {
        Header: 'Jour',
        accessor: 'dayName',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Entrée T.',
        accessor: 'theoriticalStart',
      },
      {
        Header: 'Sortie T.',
        accessor: 'theoriticalEnd',
      },
      {
        Header: 'Total T.',
        accessor: 'theoreticalDuration',
      },
      {
        Header: 'Entrée P.',
        accessor: 'countedStart',
      },
      {
        Header: 'Sortie P.',
        accessor: 'countedEnd',
      },
      {
        Header: 'Pause',
        accessor: 'countedBreak',
      },
      {
        Header: 'Total P.',
        accessor: 'countedDuration',
      } /*
      {
        Header: 'TTHS',
        accessor: 'overtime',
      },*/,
      {
        Header: 'HS Contrat',
        accessor: 'contractualOvertime',
      },
      {
        Header: 'Panier',
        accessor: 'basket',
      },
      {
        Header: 'RC',
        accessor: 'rc',
      },
      ...benefitEvents.map(e => ({
        Header: <Element title={buildWorkPeriodEventLabel(e)}>{e.code}</Element>,
        accessor: `event_${e.code}`,
      })),
      {
        Header: 'Evèn.',
        accessor: 'events',
      },
      {
        Header: ' ',
        accessor: 'messages',
      },
    ],
    [events],
  )
}

const WeekWorkedDurationCell: React.FC<{
  weekSummary?: UserSummaries['weeks'][0]
  weeklyHoursWorked?: number
}> = ({ weekSummary }) => {
  const workedTotal = weekSummary?.summary.durations.worked?.total || 0

  const diffBetweenWorkedDurationAndContract = weekSummary?.summary.durations.worked.contract.diff

  return (
    <Element
      textColor={diffBetweenWorkedDurationAndContract ? 'danger' : 'normal'}
      data-tooltip={
        diffBetweenWorkedDurationAndContract
          ? 'Les heures prestées ne correspondent pas au contrat'
          : undefined
      }
    >
      {formatDuration(workedTotal)}
    </Element>
  )
}

export const DurationDiff: React.FC<{
  duration?: number
  durationToSubstract?: number
  showZero?: boolean
}> = ({ duration, durationToSubstract, showZero }) => {
  const diff = (duration || 0) - (durationToSubstract || 0)
  return (
    <Element renderAs="span">
      {diff < 0 ? '-' : ''}
      {showZero && !diff ? '0' : diff ? formatDuration(diff) : ''}
    </Element>
  )
}
