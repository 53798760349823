import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Button, Element, Level } from 'react-bulma-components'
import { useAuthMutation } from '../../queries/users'
import InputField from '../form/fields/input'
import { PasswordField } from '../form/fields/password'
import { RequestButton } from '../request-components/request-components'
import { Link } from 'react-router-dom'
import { RequestIconWithMessage } from '../request-components/request-components'

const LoginForm: React.FC = () => {
  const authMutation = useAuthMutation()

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.email) errors.email = 'Champ requis'
        if (!values.password) errors.password = 'Champ requis'
        return errors
      }}
      onSubmit={values => authMutation.mutate(values)}
    >
      {() => {
        return (
          <Form>
            <Element mb={5}>
              <Field label="Email" name="email" component={InputField} type="email" required />
              <PasswordField label="Mot de passe" name="password" component={InputField} />
              <Element mt={5} textAlign="center">
                <RequestIconWithMessage
                  mutation={authMutation}
                  displayOn={{ error: 'Email ou mot de passe inconnu' }}
                />
              </Element>
            </Element>
            <Level mt={5}>
              <Level.Side>
                <Button color="ghost" renderAs={Link} to="/request-new-password">
                  Mot de passe perdu ?
                </Button>
              </Level.Side>
              <Level.Side>
                <RequestButton color="primary" type="submit" mutation={authMutation}>
                  Se connecter
                </RequestButton>
              </Level.Side>
            </Level>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LoginForm
