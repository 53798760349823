import { ResourceRef, ResourceRefOrSchema } from '../../../backend/src/types/common.types'

export const compareDocsById = (doc: any, docToCompare: any): boolean => {
  const docId = doc?._id ? doc?._id.toString() : doc?.toString()
  const docIdToCompare = docToCompare?._id ? docToCompare?._id.toString() : docToCompare?.toString()
  return docId === docIdToCompare
}

export const getDocInArray = <T>(docs: T[], docOrId: ResourceRefOrSchema<T>): T | undefined =>
  docs.find(doc => compareDocsById(doc, docOrId))
