import React from 'react'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import FormWrapper from '../../components/form/form-wrapper'
import {
  Box,
  Button,
  Columns,
  Container,
  Element,
  Form,
  Heading,
  Icon,
  Level,
  Section,
} from 'react-bulma-components'
import EmployerForm from '../../components/organizations/employer-form'
import ComponentHeader from '../../components/sections/component-header'
import { ViewListIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import Protected from '../../components/protected/protected'
import useStore from '../../store'
import { Cell, Column } from 'react-table'
import { EmployerAssociation } from '../../../../backend/src/services/resources/organizations/organization.model'
import Table from '../../components/table'
import RoleStatus from '../../components/users/role-status'
import { User } from '../../api/users'
import { useMutationState } from '../../queries'
import { Employer, InterimAgency } from '../../api/organizations'
import useUrlId from '../../hooks/use-url-id'
import {
  useCreateMutationKey,
  useOrganizationQuery,
  useUpdateAssocationMutatuion,
  useUpdateMutationKey,
} from '../../queries/organizations'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import Avatar from '../../components/avatar/avatar'
import PageTitle from '../../components/pages/page-title'

const EmployerDetails: React.FunctionComponent = () => {
  const currentOrganization = useStore(state => state.session.currentOrganization) as Employer

  const { id, isNew } = useUrlId()
  const { data: organization } = useOrganizationQuery<Employer>(id, 'employer')

  const mutationState = useMutationState(isNew ? useCreateMutationKey() : useUpdateMutationKey())

  const associationsColumns = useAssociationsListTable(
    currentOrganization?.associations || [],
    currentOrganization,
  )

  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    mutationState,
    !isNew,
  )

  return (
    <Layout>
      <Section>
        <Container max={true} breakpoint="desktop">
          <Level alignItems="flex-start" mb={6}>
            <Level.Side>
              <PageTitle
                title={isNew ? 'Nouvel Employeur' : organization?.name || ''}
                subTitle={
                  isNew ? 'Créer un Nouvel Employeur' : "Paramètres et Relations de l'Employeur"
                }
              />
            </Level.Side>
            <Level.Side>
              <Protected roles={['superAdmin']}>
                <Button renderAs={Link} to="/employers" outlined color="primary" size="small">
                  <Icon>
                    <ViewListIcon />
                  </Icon>
                  <span>Liste des Employeurs</span>
                </Button>
              </Protected>
            </Level.Side>
          </Level>
          <Box>
            <FormWrapper {...formWrapperProps}>
              <EmployerForm organization={organization} />
            </FormWrapper>
          </Box>
          {!isNew && (
            <Box mt={6}>
              <Heading renderAs="h2" size={4} textWeight="bold">
                Relations de l'Employeur
              </Heading>
              <Table columns={associationsColumns} data={organization?.associations || []} />
            </Box>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export default EmployerDetails

const useAssociationsListTable = (
  associations: Employer['associations'],
  organization: Employer,
): Column[] => {
  const updateAssociationMutation = useUpdateAssocationMutatuion()
  return React.useMemo(
    () =>
      [
        {
          Header: "Agence d'intérim",
          accessor: 'interimAgncy',
          Cell: (cell: Cell<EmployerAssociation>) => {
            const association = cell.row.original
            const interimAgency = association.interimAgency as InterimAgency
            return <Element>{interimAgency.name}</Element>
          },
        },
        {
          Header: "Statut de l'invitation",
          accessor: 'status',
          Cell: (data: any) => {
            return <RoleStatus role={data.cell.row.original} showDate />
          },
        },
        {
          Header: 'Invité par',
          accessor: 'invitedBy',
          Cell: (cell: Cell<EmployerAssociation>) => {
            const user = cell.row.original.invitedBy as User
            return user ? <Avatar {...user} /> : ''
          },
        },
        {
          Header: 'Action',
          accessor: '__actions',
          Cell: (data: any) => {
            const association: EmployerAssociation = data.cell.row.original
            if (association.status === 'pending')
              return (
                <Protected roles={['employerMember']}>
                  <Button
                    size={'small'}
                    color="success"
                    mr={1}
                    onClick={() =>
                      updateAssociationMutation.mutate({
                        employerId: organization._id.toString(),
                        associationId: association._id as string,
                        status: 'accepted',
                      })
                    }
                  >
                    Accepter
                  </Button>
                </Protected>
              )
            return ''
          },
        },
      ] as Column[],
    [associations],
  )
}

const formWrapperBaseProps = {
  loadingProps: { body: "Enregistrement de l'Employeur..." },
  successProps: {
    title: 'Employeur créé',
    body: {
      description: "L'Employeur a été créé",
      actions: [
        {
          label: 'Nouvel Employeur',
        },
        {
          label: 'Retourner à la liste',
          to: '/employers',
        },
      ],
    },
  },
}
