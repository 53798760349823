import { upperFirst } from 'lodash'
import React from 'react'
import { Icon } from 'react-bulma-components'
import { Mission } from '../../../api/missions'
import { WorkPeriod } from '../../../api/work-periods'
import { formatCompleteDate } from '../../../utils/date'
import Avatar from '../../avatar/avatar'
import { EmployerServiceIcon, JobTitleIcon } from '../../icons'
import PageTitle from '../../pages/page-title'

interface WorkPeriodPageTitleProps {
  workPeriod: WorkPeriod
  mission: Mission
  children: React.ReactNode
}

const WorkPeriodPageTitle: React.FC<WorkPeriodPageTitleProps> = ({
  workPeriod,
  mission,
  children,
}) => (
  <PageTitle
    title={upperFirst(formatCompleteDate(workPeriod.start.date))}
    subTitle={
      <>
        <b>
          <Icon.Text mr={5}>
            <Avatar {...mission.user} />
          </Icon.Text>
        </b>
        <Icon.Text mr={5}>
          <Icon mr={1}>
            <EmployerServiceIcon />
          </Icon>
          Menuiserie
        </Icon.Text>
        <Icon.Text mr={5}>
          <Icon mr={1}>
            <JobTitleIcon />
          </Icon>
          Menuisier
        </Icon.Text>
      </>
    }
  >
    {children}
  </PageTitle>
)

export default WorkPeriodPageTitle
