import { MutationFunction } from '@tanstack/react-query'
import api, { ApiResponse } from '.'
import { UserSchema } from '../../../backend/src/services/resources/users/user.model'
import { MissionsFilters } from '../store/filters'
import { Mission } from './missions'
import { WorkPeriodEvent } from './work-period-events'
import { WorkPeriodSummary } from '../../../backend/src/services/resources/missions/missions.summary.service.types'
import { WorkPeriodClocking } from '../../../backend/src/services/resources/missions/mission.model'
import { ClockingRules } from './clocking-rules'

type FetchWorkPeriodsParams = MissionsFilters

export interface WorkPeriodWithSummaryComment {
  message?: string
  user?: UserSchema
}

export interface WorkPeriodWithSummary extends WorkPeriod {
  summary: WorkPeriodSummary
}

interface WorkPeriodClockingTimeComment {
  createdAt: Date
  message: string
  user: UserSchema
}

interface WorkPeriodClockingTime {
  date: Date
  clocking?: WorkPeriodClocking
  manualClocking: Date
  comments: WorkPeriodClockingTimeComment[]
}

export interface WorkPeriod {
  start: WorkPeriodClockingTime
  end: WorkPeriodClockingTime
  manualBreak?: number
  breaksClockings: WorkPeriodClocking[]
  week: number
  year: number
  isValidated: boolean
  isAbandoned: boolean
  clockingRules: ClockingRules
  isCancelled: boolean
  preBilling?: string
  _id: string
  mission: Mission
  events: {
    item: WorkPeriodEvent
    comment: string
  }[]
  __actions: {
    canBeValidated: boolean
    canBeInvalidated: boolean
    canBeUpdated: boolean
    canBeAbandoned: boolean
    canBeCancelled: boolean
    canBeManualClocked: boolean
    canBePreBilled: boolean
    canBeReplaced: boolean
  }
}

export interface WorkPeriodCreationPayload {
  missionId: string
  start: { date: Date }
  end: { date: Date }
}

export interface WorkPeriodUpdatePayload {
  missionId: string
  workPeriodId: string
  start?: { date?: Date; manualClocking?: Date | null; comment?: string }
  end?: { date?: Date; manualClocking?: Date | null; comment?: string }
  manualBreak?: number | null
  isValidated?: boolean
  isAbandoned?: boolean
  isCancelled?: boolean
  isReplaceable?: boolean
  events?: { item: string; comment?: string }[]
}

export interface WorkPeriodManyUpdatePayload {
  missionId: string
  workPeriodsIds: string[]
  isValidated?: boolean
  events?: { item: string; comment: string }[]
}

interface WorkPeriodRemovePayload {
  missionId: string
  workPeriodId: string
}

export interface WorkPeriodWithMission extends WorkPeriod {
  mission: Mission
}

export interface WorkPeriodWithMissionAndSummary
  extends WorkPeriodWithMission,
    WorkPeriodWithSummary {}

export const fetch = async (data: any): Promise<ApiResponse<WorkPeriodWithMission>> =>
  await api({
    endpoint: '/work-periods',
    method: 'get',
    params: data,
  })

export const create: MutationFunction<
  ApiResponse<Mission>,
  WorkPeriodCreationPayload
> = async data => {
  const { missionId, ...payload } = data
  return await api({
    endpoint: `/missions/${missionId}/work-periods`,
    method: 'post',
    params: payload,
  })
}

export const update: MutationFunction<
  ApiResponse<Mission>,
  WorkPeriodUpdatePayload
> = async data => {
  const { missionId, workPeriodId, ...payload } = data
  return await api({
    endpoint: `/missions/${missionId}/work-periods/${workPeriodId}`,
    method: 'put',
    params: payload,
  })
}

export const updateMany: MutationFunction<
  ApiResponse<Mission>,
  WorkPeriodManyUpdatePayload
> = async data => {
  const { missionId, ...payload } = data
  return await api({
    endpoint: `/missions/${missionId}/work-periods`,
    method: 'put',
    params: payload,
  })
}

export const remove: MutationFunction<
  ApiResponse<Mission>,
  WorkPeriodRemovePayload
> = async data => {
  const { missionId, workPeriodId } = data
  return await api({
    endpoint: `/missions/${missionId}/work-periods/${workPeriodId}`,
    method: 'delete',
  })
}
