import React from 'react';
export default function useRect(element, enabled) {
  var rerender = React.useReducer(function () {
    return {};
  }, [])[1];
  var rectRef = React.useRef({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  });
  var measure = React.useCallback(function () {
    if (element) {
      rectRef.current = element.getBoundingClientRect();
    }
  }, [element]);

  if (!rectRef.current) {
    measure();
  }

  React.useEffect(function () {
    if (!element || !enabled) {
      return;
    }

    var cb = function cb() {
      measure();
      rerender();
    };

    document.addEventListener('scroll', cb, true);
    return function () {
      document.removeEventListener('scroll', cb, true);
    };
  }, [element, enabled, measure, rerender]);
  React.useEffect(function () {
    if (!element || !enabled) {
      return;
    }

    measure();
    rerender();
    var observer = new ResizeObserver(function () {
      measure();
      rerender();
    });
    observer.observe(element);
    return function () {
      observer.unobserve(element);
    };
  }, [element, enabled, measure, rerender]);
  return rectRef.current;
}