import React from 'react'
import { Element, Panel } from 'react-bulma-components'
import { formatShortDateTime } from '../../utils/date'
import Avatar from '../avatar/avatar'
import { HistoryItem, HistoryLocales } from './history'
import HistoryChange from './history-change'

interface HistoryItemProps {
  item: HistoryItem
  renderCustomContent?(historyItem: HistoryItem, locales?: HistoryLocales): JSX.Element
  locales?: HistoryLocales
}

const HistoryItem: React.FC<HistoryItemProps> = ({ item, locales, renderCustomContent }) => {
  return (
    <Panel.Block>
      <details>
        <summary>
          <Avatar {...item.by} small />
          <Element
            renderAs="span"
            ml={3}
            style={{ whiteSpace: 'nowrap' }}
            className="is-size-7-mobile"
          >
            {formatShortDateTime(item.date)}
          </Element>
          <Element renderAs="span" ml={3} style={{ whiteSpace: 'nowrap' }}>
            {locales?.title?.[item.action] || item.action}
          </Element>
        </summary>
        {item.changes && item.changes[0] && (
          <Element>
            {item.changes.map(change => (
              <HistoryChange key={change._id} change={change} locales={locales} />
            ))}
          </Element>
        )}
        {renderCustomContent && (
          <Element my={3} ml={3}>
            {renderCustomContent(item, locales)}
          </Element>
        )}
      </details>
    </Panel.Block>
  )
}

export default HistoryItem
