import { isToday } from 'date-fns'
import React from 'react'
import { useWorkPeriodsQuery } from '../../queries/work-periods'
import { DashboardCard } from './dashboard-card'
import { endOfWeek, startOfWeek } from '../../utils/date'
import WorkerLabel from '../texts/worker-label'

export const WorkPeriodsDashboardCard: React.FC = () => {
  const workPeriodsQuery = useWorkPeriodsQuery({
    populate: [],
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date()),
    fields: ['_id', 'workPeriods'],
    isCancelled: false,
  })
  const workPeriodsToday = workPeriodsQuery?.data?.filter(workPeriod =>
    isToday(new Date(workPeriod?.start.date)),
  )
  const workPeriodsTodayTotal = workPeriodsToday?.length ?? 0
  const clockedInTotal =
    workPeriodsToday?.filter(
      workPeriod => workPeriod.start.clocking || workPeriod.start.manualClocking,
    )?.length ?? 0
  const clockedOutTotal =
    workPeriodsToday?.filter(workPeriod => workPeriod.end.clocking || workPeriod.end.manualClocking)
      ?.length ?? 0

  return (
    <DashboardCard
      title={"AUJOURD'HUI"}
      mainStat={{ value: workPeriodsTodayTotal, label: <WorkerLabel plural /> }}
      secondaryStat={
        <>
          {clockedInTotal} Entrées, {clockedOutTotal} Sorties
        </>
      }
      to={'/work-periods'}
      queryStatus={workPeriodsQuery.status}
    />
  )
}
