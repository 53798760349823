import get from 'lodash/get'
import mergeWith from 'lodash/mergeWith'
import setWith from 'lodash/setWith'

export const mergeWithReplacedArrays = <OldValue = object, NewValue = object>(
  oldValue: OldValue,
  newValue: NewValue,
): OldValue & NewValue => {
  return mergeWith(oldValue, newValue, (a, b) => (Array.isArray(b) ? b : undefined))
}

export type ObjectOfNumbers = { [key: string]: number | ObjectOfNumbers }

export const sumNumbersOfObjects = <T = ObjectOfNumbers>(objects: object[]): T => {
  const res = {}

  const setOrAddValue = (values: object, parent?: string) => {
    for (const key in values) {
      const value = values[key]
      const valuePath = parent ? `${parent}[${key}]` : `[${key}]`
      const currentValue = get(res, valuePath)
      if (typeof value === 'number') {
        if (currentValue === undefined) setWith(res, valuePath, value, Object)
        else setWith(res, valuePath, currentValue + value, Object)
      } else if (typeof value === 'object') setOrAddValue(value, valuePath)
    }
  }

  objects.forEach(object => setOrAddValue(object))
  return res as T
}
