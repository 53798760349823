import React from 'react'
import { Organization, OrganizationTypeEnum } from '../../../api/organizations'
import MultiSelectField from '../../form/fields/multi-select'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import { buildListAsInputOptions } from '../../../utils/forms'
import useAssociatedOrganizationsQueryParams from '../../../hooks/use-associated-organizations-query-param'
import { useOrganizationsQuery } from '../../../queries/organizations'

interface OrganizationFilterFieldProps {
  disabledQueries?: boolean
  queriesStaleTime?: number
  type: OrganizationTypeEnum
}

const OrganizationFilterField: React.FC<OrganizationFilterFieldProps> = ({
  type,
  disabledQueries,
  queriesStaleTime,
}) => {
  const organizationsQueryParams = useAssociatedOrganizationsQueryParams()

  const organizationsQuery = useOrganizationsQuery(organizationsQueryParams, {
    enabled: !disabledQueries && Boolean(Object.keys(organizationsQueryParams)[0]),
    staleTime: queriesStaleTime,
  })

  return (
    <FieldColumn>
      <Field
        label={type === OrganizationTypeEnum.employer ? 'Employeur' : 'Agence'}
        name={type === OrganizationTypeEnum.employer ? 'employer' : 'interimAgency'}
        component={MultiSelectField}
        options={buildListAsInputOptions(organizationsQuery.data || [])}
      />
    </FieldColumn>
  )
}

export default OrganizationFilterField
