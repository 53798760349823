import React from 'react'
import { Button, Columns, Container, Element, Form, Icon, Section } from 'react-bulma-components'
import { CellProps, Column } from 'react-table'
import Layout from '../../components/layout/layout'
import Table from '../../components/table'
import PageHeading from '../../components/sections/page-heading'
import MissionsFilters from '../../components/missions/filters'
import { WorkPeriod, WorkPeriodWithMissionAndSummary } from '../../api/work-periods'
import { Link } from 'react-router-dom'
import { ViewListIcon } from '@heroicons/react/solid'
import WorkPeriodValidateConfirmationModal from '../../components/work-periods/modals/validate-confirmation'
import { endOfWeek, formatTime, localDate, startOfWeek } from '../../utils/date'
import WorkPeriodValidateButton from '../../components/work-periods/validate-button'
import ComponentHeader from '../../components/sections/component-header'
import Protected from '../../components/protected/protected'
import { SessionSlice } from '../../store/session'
import useStore from '../../store'
import { useWorkPeriodsQuery } from '../../queries/missions'
import {
  WorkPeriodAbandonedMessage,
  WorkPeriodCancelledMessage,
  WorkPeriodValidatedMessage,
} from '../../components/work-periods/work-period-messages'
import {
  workPeriodSummaryHeaders,
  WorkPeriodSummaryValue,
} from '../../components/table/summary-cell'
import { isBefore, isSameDay, isWithinInterval } from 'date-fns'
import { getUserLabel } from '../../utils/users'
import { FiltersResourcesEnum } from '../../store/filters'
import useFilters from '../../hooks/filters'
import WorkerLabel from '../../components/texts/worker-label'

const WorkPeriodsList: React.FunctionComponent = () => {
  const { filters } = useFilters(FiltersResourcesEnum.missions)
  // The view is filtered by day browser side, not with the query #improve
  const currentDaySelected = filters.displayDay ? new Date(filters.displayDay) : null
  const query = useWorkPeriodsQuery()
  const workPeriods =
    query.data
      ?.filter(wP => {
        // Filter by day, or return the whole week
        return currentDaySelected ? isSameDay(new Date(wP.start.date), currentDaySelected) : true
      })
      ?.filter(wP => {
        const isStarted = Boolean(wP.start.clocking ?? wP.start.manualClocking)
        const isEnded = Boolean(wP.end.clocking ?? wP.end.manualClocking)
        switch (filters.workPeriodType) {
          case 'coming':
            return !isStarted && !isEnded
          case 'started':
            return isStarted && !isEnded
          case 'ended':
            return isEnded
          default:
            return true
        }
      })
      ?.filter(wP =>
        isWithinInterval(new Date(wP.start.date), {
          start: filters.start ? new Date(filters.start) : startOfWeek(new Date()),
          end: filters.end ? new Date(filters.end) : endOfWeek(new Date()),
        }),
      )
      // Sort by date/hour
      .sort((a, b) => (isBefore(a.start.date, b.start.date) ? -1 : 1)) ?? []

  const workPeriodsToValidate = React.useMemo(
    () => workPeriods.filter((workPeriod: WorkPeriod) => !workPeriod.isValidated),
    [workPeriods],
  )
  //Validate work period state
  const [currentWorkPeriodToValidate, setCurrentWorkPeriodToValidate] = React.useState<
    WorkPeriodWithMissionAndSummary | undefined
  >()

  const currentUserRole = useStore(state => state.session.currentUserRole)
  const columns = useColumns(setCurrentWorkPeriodToValidate, workPeriodsToValidate, currentUserRole)

  return (
    <Layout>
      <PageHeading title="Liste des Journées de Travail" />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left>
              <MissionsFilters showDayFilter showWorkPeriodTypeFilter />
            </ComponentHeader.Left>
            <ComponentHeader.Right>
              <Columns>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control>
                      <Button renderAs={Link} to="/missions/" outlined color="primary">
                        <Icon>
                          <ViewListIcon />
                        </Icon>
                        <span>Liste des Missions</span>
                      </Button>
                      <Element></Element>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              </Columns>
            </ComponentHeader.Right>
          </ComponentHeader>
          <Table
            columns={columns}
            data={workPeriods}
            noDataMessage="Aucune Journée de travail à afficher"
          />
        </Container>
        {currentWorkPeriodToValidate && (
          <WorkPeriodValidateConfirmationModal
            workPeriod={currentWorkPeriodToValidate}
            hide={() => setCurrentWorkPeriodToValidate(undefined)}
          />
        )}
      </Section>
    </Layout>
  )
}

export default WorkPeriodsList

const useColumns = (
  setCurrentWorkPeriodToValidate: React.Dispatch<
    React.SetStateAction<WorkPeriodWithMissionAndSummary | undefined>
  >,
  workPeriodsToValidate: WorkPeriod[],
  currentUserRole: SessionSlice['currentUserRole'],
): Column<WorkPeriodWithMissionAndSummary>[] =>
  React.useMemo(() => {
    const columns: (Column<WorkPeriodWithMissionAndSummary> | false)[] = [
      {
        Header: <WorkerLabel />,
        id: 'user',
        accessor: workPeriod => workPeriod.mission.user.firstName,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <span style={{ whiteSpace: 'nowrap' }}>{getUserLabel(workPeriod.mission.user)}</span>
          )
        },
      },
      {
        Header: 'Journée',
        accessor: workPeriod => workPeriod.start.date,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <>
              <Protected roles={['employerMember']}>
                <Link to={`/missions/${workPeriod.mission._id}/work-periods/${workPeriod._id}`}>
                  {localDate(workPeriod.start.date)}
                </Link>
              </Protected>
              <Protected roles={['worker']}>{localDate(workPeriod.start.date)}</Protected>
            </>
          )
        },
      },
      {
        Header: 'Début Th.',
        accessor: 'start.theoritical',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <Element renderAs="span" textColor="dark">
              {formatTime(new Date(workPeriod.start.date))}
            </Element>
          )
        },
        width: 1,
      },
      {
        Header: 'Fin Th.',
        accessor: 'end.theoritical',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return (
            <Element renderAs="span" textColor="dark">
              {formatTime(new Date(workPeriod.end.date))}
            </Element>
          )
        },
        width: 1,
      },
      {
        Header: 'Début',
        accessor: 'start',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <WorkPeriodSummaryValue
              workPeriod={workPeriod}
              mission={workPeriod.mission}
              workPeriodSummaryHeader={workPeriodSummaryHeaders[0]}
            />
          )
        },
        width: 1,
      },
      {
        Header: 'Fin',
        accessor: 'end',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return (
            <WorkPeriodSummaryValue
              workPeriod={workPeriod}
              mission={workPeriod.mission}
              workPeriodSummaryHeader={workPeriodSummaryHeaders[1]}
            />
          )
        },
        width: 1,
      },
      currentUserRole !== 'interimAgencyMember' && {
        Header: 'Agence',
        accessor: workPeriod => workPeriod.mission.interimAgency?.name,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return workPeriod.mission.interimAgency?.name ?? null
        },
      },
      currentUserRole !== 'employerMember' && {
        Header: 'Employeur',
        accessor: workPeriod => workPeriod.mission.employer.name,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return workPeriod.mission.employer?.name || ' '
        },
      },
      {
        Header: 'Service',
        accessor: workPeriod => workPeriod.mission.service,
      },
      {
        Header: 'Poste',
        accessor: workPeriod => workPeriod.mission.jobTitle.name,
      },
      currentUserRole !== 'worker' && {
        Header: (
          <WorkPeriodValidatedMessage
            workPeriod={{ isValidated: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isValidated',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return (
            <>
              <WorkPeriodValidatedMessage workPeriod={workPeriod} type="icon" small />
            </>
          )
        },
        width: 1,
      },
      currentUserRole !== 'worker' && {
        Header: (
          <WorkPeriodAbandonedMessage
            workPeriod={{ isAbandoned: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isAbandoned',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return <WorkPeriodAbandonedMessage workPeriod={workPeriod} type="icon" small />
        },
        width: 1,
      },
      currentUserRole !== 'worker' && {
        Header: (
          <WorkPeriodCancelledMessage
            workPeriod={{ isCancelled: true }}
            type="icon"
            small
            iconColor={'dark'}
          />
        ),
        accessor: 'isCancelled',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return <WorkPeriodCancelledMessage workPeriod={workPeriod} type="icon" small />
        },
        width: 1,
      },
      currentUserRole !== 'worker' && {
        Header: 'Mission',
        accessor: 'mission',
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod: WorkPeriodWithMissionAndSummary = data.cell.row.original
          return (
            <>
              {localDate(workPeriod.mission.start)}&nbsp;-&nbsp;{localDate(workPeriod.mission.end)}
            </>
          )
        },
      },
      currentUserRole !== 'worker' && {
        Header: 'Valider',
        accessor: workPeriod => workPeriod.__actions.canBeValidated,
        Cell: (data: CellProps<WorkPeriodWithMissionAndSummary>) => {
          const workPeriod = data.cell.row.original
          return (
            <Protected
              roles={['employerMember']}
              action={{ resource: 'missions', name: 'validateWorkPeriod' }}
            >
              <WorkPeriodValidateButton
                workPeriod={workPeriod}
                size="smallest"
                onClick={() => setCurrentWorkPeriodToValidate(workPeriod)}
              />
            </Protected>
          )
        },
      },
    ]
    return columns.flatMap(c => (c ? [c] : []))
  }, [workPeriodsToValidate, currentUserRole])
