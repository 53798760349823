import React from 'react'
import { Field, Form, Formik, FormikErrors } from 'formik'
import { Card, Columns, Element, Heading } from 'react-bulma-components'
import useStore from '../../store'
import { formatCompleteDate } from '../../utils/date'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import {
  addDays,
  differenceInDays,
  formatISO,
  isAfter,
  isBefore,
  isWithinInterval,
  nextSunday,
  parseISO,
} from 'date-fns'
import AutocompleteField from '../form/fields/autocomplete'
import SelectField from '../form/fields/select'
import ComponentFooter from '../sections/component-footer'
import { Organization } from '../../api/organizations'
import { useCreateMutation, useUpdateMutation } from '../../queries/missions'
import { buildListAsInputOptions } from '../../utils/forms'
import { Mission } from '../../api/missions'
import { AvailableWorker, User } from '../../api/users'
import { useUsersQuery } from '../../queries/users'
import { useJobTitlesQuery } from '../../queries/job-titles'
import { useWorkersRequestsServicesQuery } from '../../queries/workers-requests'
import FormWrapper from '../form/form-wrapper'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import { WorkPeriodsEditor } from '../work-periods/work-periods-editor/work-periods-editor'
import { cloneDeep, isEqual, truncate } from 'lodash'
import { WorkPeriod } from '../../api/work-periods'
import {
  getWorkerAbsentLabelForOrganization,
  getUserLabel,
  workerIsIsAvailableForOrganizationAndDay,
  getWorkerAvailabilityForOrganization,
  getWorkerAbsentLabel,
  getWorkerAndOrganizationAssociation,
} from '../../utils/users'
import WorkerLabel from '../texts/worker-label'
import InputField from '../form/fields/input'
import { WorkPeriodEditorFormErrors } from '../work-periods/work-periods-editor/work-period-editor'

interface MissionFormProps {
  mission?: Mission
  readOnly?: boolean
}

export interface MissionFormValues {
  meta: {
    startDate: string // YYYY-mm-dd
    endDate: string // YYYY-mm-dd
    users: User[]
    resource?: Mission
  }
  user: string
  employer: string
  address: string
  workPeriods: Array<
    {
      start?: { date: Date }
      end?: { date: Date }
    } & Partial<Omit<WorkPeriod, 'start' | 'end'>>
  >
  isValidated: boolean
  service: string
  jobTitle: string
  lastWeek: string
}

type MissionFormErrors = FormikErrors<MissionFormValues> & WorkPeriodEditorFormErrors

const MissionForm: React.FunctionComponent<MissionFormProps> = ({ mission, readOnly = false }) => {
  const currentOrganization = useStore(state => state.session.currentOrganization) as Organization
  const employerId = mission?.employer?._id.toString() || currentOrganization._id.toString()

  //Is new mission or mission edition
  const isMissionEditForm = Boolean(mission)
  const isNewMissionForm = !isMissionEditForm

  const jobTitlesQuery = useJobTitlesQuery(
    {
      employer: employerId,
    },
    { enabled: Boolean(employerId) },
  )

  const missionsServicesQuery = useWorkersRequestsServicesQuery(
    {
      employer: [employerId],
    },
    { enabled: Boolean(employerId) },
  )

  const usersQuery = useUsersQuery({
    'roles.kind': 'worker',
    filter: JSON.stringify({
      associations: {
        $elemMatch: {
          organization: currentOrganization._id,
          status: ['accepted', 'pending'],
        },
      },
    }),
  })

  const missionNotEditable = Boolean((mission && !mission.__actions.canBeUpdated) || readOnly)
  const missionEditable = !missionNotEditable
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const currentMutation = mission ? updateMutation : createMutation
  const formWrapperBaseProps = isNewMissionForm
    ? formWrapperBaseCreateProps
    : formWrapperBaseUpdateProps
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    currentMutation,
  )
  let usersList: User[]
  let servicesList: Mission['service'][]

  if (missionNotEditable) {
    usersList = mission?.user ? [mission.user as User] : []
    servicesList = mission?.service ? [mission.service] : []
  } else {
    usersList = usersQuery?.data || []
    servicesList = missionsServicesQuery?.data || []
  }

  // start and end dates stored as ISO dates YYYY-mm-dd
  // we always display at least 3 days
  const now = new Date()
  const today = now
  const todayISO = formatISO(now, { representation: 'date' })
  const sunday = nextSunday(now)
  const end = differenceInDays(sunday, today) <= 3 ? addDays(sunday, 7) : sunday
  const endISO = formatISO(end, { representation: 'date' })

  const startDateISO = mission?.start
    ? formatISO(new Date(mission.start), { representation: 'date' })
    : todayISO
  const lastWorkPeriodStartDate = mission?.workPeriods[mission.workPeriods.length - 1]?.start.date
  const endDateISO = lastWorkPeriodStartDate
    ? formatISO(new Date(lastWorkPeriodStartDate), {
        representation: 'date',
      })
    : endISO

  return (
    <FormWrapper {...formWrapperProps}>
      <Formik<MissionFormValues>
        initialValues={{
          meta: {
            startDate: startDateISO,
            endDate: endDateISO,
            users: usersList,
            resource: mission,
          },
          user: mission?.user?._id || '',
          employer: mission?.employer?._id.toString() || currentOrganization._id.toString(),
          address: mission?.address ?? '',
          workPeriods:
            mission?.workPeriods.map(wP => ({
              ...wP,
              start: { date: new Date(wP.start.date) },
              end: { date: new Date(wP.end.date) },
            })) ?? [],
          isValidated: false,
          service: mission?.service ?? '',
          jobTitle: (mission?.jobTitle?._id as string) || '',
          lastWeek: '',
        }}
        enableReinitialize
        validate={values => {
          const errors: MissionFormErrors = {}

          if (!values.user) errors['user'] = 'Champ requis'
          if (!values.service) errors['service'] = 'Champ requis'
          if (!values.employer) errors['employer'] = 'Champ requis'
          if (!values.jobTitle) errors['jobTitle'] = 'Champ requis'
          if (!values.address) errors.address = 'Champ requis'

          const startDate = parseISO(values.meta.startDate)
          const endDate = parseISO(values.meta.endDate)
          const worker = usersQuery.data?.find(u => u._id === values.user)

          // If no workPeriod at all
          if (!values.workPeriods || values.workPeriods.length === 0)
            errors['workPeriods'] = "Pas d'horaire de mission"

          // If startDate is after endDate, this is not gonna work..
          if (isAfter(startDate, endDate)) {
            if (errors.meta === undefined) errors.meta = {}
            errors.meta['startDate'] = 'La date de début doit être avant la date de fin'
          }

          // If startDate<>endDate length is > 365 days, this is not allowed
          if (Math.abs(differenceInDays(startDate, endDate)) > 365) {
            if (errors.meta === undefined) errors.meta = {}
            errors.meta['endDate'] = 'Une mission doit durer maximum 1 an'
          }

          for (let index = 0; index <= values.workPeriods.length; index++) {
            const workPeriod = values.workPeriods[index]
            const nextWorkPeriod = values.workPeriods[index + 1]
            const firstWorkPeriodStartDate =
              workPeriod && workPeriod.start ? workPeriod.start.date : undefined
            const firstWorkPeriodEndDate =
              workPeriod && workPeriod.end ? workPeriod.end.date : undefined
            const nextWorkPeriodStartDate =
              nextWorkPeriod && nextWorkPeriod.start ? nextWorkPeriod.start.date : undefined

            // If the end of a workPeriod overlaps the start of the next workPeriod
            if (firstWorkPeriodStartDate && firstWorkPeriodEndDate && nextWorkPeriodStartDate) {
              if (
                isBefore(nextWorkPeriodStartDate, firstWorkPeriodEndDate) ||
                isEqual(firstWorkPeriodEndDate, nextWorkPeriodStartDate)
              ) {
                const niceDate = formatCompleteDate(firstWorkPeriodStartDate)
                const isoDate = formatISO(firstWorkPeriodStartDate, { representation: 'date' })
                const error = `${niceDate} : L'heure de fin ne peut être plus grande ou égale à l'heure du début du jour suivant`
                errors['workPeriods'] = error
                ;(errors[`workPeriod-${isoDate}`] ??= []).push(error)
              }
            }

            // If a workPeriod has no start date set
            if (firstWorkPeriodStartDate === undefined && firstWorkPeriodEndDate) {
              const date = firstWorkPeriodStartDate ?? firstWorkPeriodEndDate
              const niceDate = formatCompleteDate(date)
              const isoDate = formatISO(date, { representation: 'date' })
              const error = `${niceDate} : L'heure de début n'est pas précisée`
              errors['workPeriods'] = error
              ;(errors[`workPeriod-${isoDate}`] ??= []).push(error)
            }
            // If a workPeriod has no end date set
            if (firstWorkPeriodStartDate && firstWorkPeriodEndDate === undefined) {
              const date = firstWorkPeriodStartDate ?? firstWorkPeriodEndDate
              const niceDate = formatCompleteDate(date)
              const isoDate = formatISO(date!, { representation: 'date' })
              const error = `${niceDate} : L'heure de fin n'est pas précisée`
              errors['workPeriods'] = error
              ;(errors[`workPeriod-${isoDate}`] ??= []).push(error)
            }

            const workerIsAvailable =
              workPeriod?.start?.date &&
              workPeriod?.end?.date &&
              worker &&
              workerIsIsAvailableForOrganizationAndDay(
                new Date(workPeriod.start.date),
                worker,
                currentOrganization,
              )

            if (
              !mission &&
              firstWorkPeriodStartDate &&
              worker &&
              workPeriod?.start?.date &&
              workPeriod?.end?.date &&
              !workerIsAvailable
            ) {
              errors['workPeriods'] = getWorkerAbsentLabelForOrganization(
                worker,
                currentOrganization,
              )
              const isoDate = formatISO(firstWorkPeriodStartDate, { representation: 'date' })
              if (firstWorkPeriodStartDate)
                (errors[`workPeriod-${isoDate}`] ??= []).push(
                  getWorkerAbsentLabelForOrganization(worker, currentOrganization),
                )
            }
          }

          return errors
        }}
        onSubmit={async values => {
          if (missionNotEditable) return

          const { startDate, endDate } = values.meta
          const missionStartDate = parseISO(startDate)
          const missionEndDate = addDays(parseISO(endDate), 1)

          // The form is validated, we know that each workPeriod is complete and valid
          const workPeriods = cloneDeep(
            values.workPeriods as Array<Required<MissionFormValues['workPeriods'][0]>>,
          )
            // We remove all existing work-periods before and after startDate/endDate
            .filter(wP =>
              isWithinInterval(wP.start.date, { start: missionStartDate, end: missionEndDate }),
            )
            // only start and end dates are needed
            .map(wP => {
              return {
                start: { date: wP.start.date },
                end: { date: wP.end.date },
              }
            })

          if (mission) {
            const { service, address } = values

            updateMutation.mutate({
              _id: mission._id,
              service,
              address,
              workPeriods,
            })
          } else {
            const { service, jobTitle, user, address } = values
            createMutation.mutate({
              user,
              jobTitle,
              service,
              workPeriods,
              address,
            })
          }
        }}
      >
        {props => {
          const currentClockingRule = jobTitlesQuery.data?.find(
            jT => props.values.jobTitle.toString() === jT._id.toString(),
          )?.clockingRule
          const selectedUser = usersList.find(u => u._id.toString() === props.values.user)
          return (
            <Form>
              <Element mb={6} mt={5}>
                <Heading renderAs="h2" size={4}>
                  Informations du <WorkerLabel />
                </Heading>
                <Columns>
                  {!mission && (
                    <Columns.Column>
                      <Field
                        label={<WorkerLabel />}
                        name="user"
                        component={AutocompleteField}
                        required
                        fullwidth
                        onlySelect
                        items={buildListAsInputOptions(usersList, {
                          labelBuilder: (user: User) => getUserLabel(user),
                          renderLabel: user =>
                            UserInputLabel({ user, organization: currentOrganization }),
                        })}
                        selectDefaultChooseOptionLabel="Choisir l'intérimaire"
                        disabled={missionNotEditable || isMissionEditForm}
                      />
                      {props.values.user && selectedUser && (
                        <Card>
                          <Card.Content>
                            <UserInputLabel
                              user={selectedUser}
                              organization={currentOrganization}
                            />
                          </Card.Content>
                        </Card>
                      )}
                    </Columns.Column>
                  )}
                  <Columns.Column>
                    <Field
                      label="Intitulé de Poste"
                      name="jobTitle"
                      component={SelectField}
                      required
                      fullwidth
                      options={buildListAsInputOptions(jobTitlesQuery.data || [])}
                      selectDefaultChooseOptionLabel={
                        props.values.employer
                          ? 'Choisir le Poste'
                          : "Sélectionnez d'abord un Employeur"
                      }
                      disabled={missionNotEditable || !props.values.employer || isMissionEditForm}
                    />
                  </Columns.Column>
                  <Columns.Column>
                    <Field
                      label="Service"
                      name="service"
                      component={AutocompleteField}
                      required
                      items={buildListAsInputOptions(servicesList)}
                      disabled={missionNotEditable || !props.values.employer}
                    />
                  </Columns.Column>
                </Columns>
              </Element>
              <Element mt={5} mb={5}>
                <Heading renderAs="h2" size={4}>
                  Horaire
                </Heading>
              </Element>
              <WorkPeriodsEditor
                workPeriods={props.values.workPeriods}
                jobTitle={props.values.jobTitle}
                initialWorkPeriods={props.initialValues.workPeriods}
                resource={mission}
                user={props.values.user}
                setWorkPeriods={wPs => props.setFieldValue('workPeriods', wPs)}
                errors={props.errors}
                startDate={props.values.meta.startDate}
                endDate={props.values.meta.endDate}
              />
              <Element mt={5} mb={5}>
                <Heading renderAs="h2" size={4}>
                  Adresse de la prestation
                </Heading>
                <Field
                  name="address"
                  component={AutocompleteField}
                  requirede
                  items={buildListAsInputOptions(currentOrganization?.addresses ?? [])}
                  disabled={missionNotEditable || !props.values.employer}
                />
              </Element>
              {mission?.complementaryInformations ? (
                <>
                  <Element mt={5} mb={5}>
                    <Heading renderAs="h2" size={4}>
                      Informations complémentaires
                    </Heading>
                    <Field
                      name="complementaryInformations"
                      component={InputField}
                      disabled={mission}
                    />
                  </Element>
                </>
              ) : undefined}
              <ComponentFooter>
                <ComponentFooter.Left>
                  {(isNewMissionForm || missionEditable) && (
                    <>
                      <RequestButton
                        type="submit"
                        color="primary"
                        title="submit"
                        data-test="submit-mission-form"
                        mutation={currentMutation}
                      >
                        {mission
                          ? 'Mettre à jour les données de la Mission'
                          : 'Créer une Nouvelle Mission'}
                      </RequestButton>
                      <RequestMessage mutation={currentMutation} />
                    </>
                  )}
                </ComponentFooter.Left>
                <ComponentFooter.Right>
                  <></>
                </ComponentFooter.Right>
              </ComponentFooter>
            </Form>
          )
        }}
      </Formik>
    </FormWrapper>
  )
}

export default MissionForm

const formWrapperBaseCreateProps = {
  loadingProps: { body: 'Création de la Mission...' },
  successProps: {
    title: 'Mission créée',
    body: {
      actions: [
        {
          label: 'Liste des Missions',
          to: '/missions',
        },
        {
          label: 'Créer une Nouvelle Mission Interne',
          to: '/missions',
        },
      ],
    },
  },
}
const formWrapperBaseUpdateProps = {
  loadingProps: { body: 'Mise à jour de la Mission...' },
  successProps: {
    title: 'Mission mise à jour',
    body: {
      actions: [
        {
          label: 'Liste des Missions',
          to: '/missions',
        },
        {
          label: 'Voir la Mission',
        },
      ],
    },
  },
}

export const UserInputLabel = ({
  user,
  organization,
}: {
  user: User | AvailableWorker
  organization: Organization
}): React.ReactNode => {
  const userLabel = getUserLabel(user)
  console.log(user)
  const availability =
    'availability' in user
      ? user.availability
      : getWorkerAvailabilityForOrganization(user, organization)
  const internalComment =
    'availability' in user
      ? user.internalComment
      : getWorkerAndOrganizationAssociation(user, organization)?.internalComment
  const internalInformation =
    'availability' in user
      ? user.internalInformation
      : getWorkerAndOrganizationAssociation(user, organization)?.internalInformation
  const absentLabel =
    availability &&
    (!availability.absentTo || isAfter(new Date(availability.absentTo), new Date())) &&
    getWorkerAbsentLabel(availability, user, true)
  return (
    <>
      {userLabel}
      {absentLabel && (
        <>
          <br />
          <Element renderAs="small">
            <b>Absent:</b> {absentLabel.toLowerCase()}
          </Element>
        </>
      )}

      {internalComment && (
        <Element>
          <Element renderAs="small">
            <b>Infos:</b> {truncate(internalComment, { length: 70 })}
          </Element>
        </Element>
      )}
      {internalInformation && (
        <Element>
          <Element renderAs="small">
            <b>Commentaires:</b> {truncate(internalInformation, { length: 70 })}
          </Element>
        </Element>
      )}
    </>
  )
}
