import React from 'react'
import { Element, Icon } from 'react-bulma-components'
import { Mission, MissionWithSummary } from '../../api/missions'
import {
  WorkPeriod,
  WorkPeriodWithSummary,
  WorkPeriodWithSummaryComment,
} from '../../api/work-periods'
import { formatDuration as formatDurationOriginal, formatTime } from '../../utils/date'
import {
  getEventsByType,
  getWorkPeriodFromDayDate,
  hasBenefitEvent,
} from '../../utils/work-periods'
import _get from 'lodash/get'
import { Link } from 'react-router-dom'
import StandardCell from './standard-cell'
import ReactTooltip from 'react-tooltip'
import { formatCost } from '../../utils/finance'
import WorkPeriodValidateButton from '../work-periods/validate-button'
import Protected, { isAuthorizedFor } from '../protected/protected'
import Avatar from '../avatar/avatar'
import { UserCircleIcon } from '@heroicons/react/outline'
import {
  WorkPeriodAbandonedMessage,
  WorkPeriodCancelledMessage,
  WorkPeriodEventsMessage,
} from '../work-periods/work-period-messages'
import ClockingRulesDescription from '../clocking-rules/clocking-rules-description'

const countedLabels = {
  theoretical: 'Théorique',
  clocked: 'Pointage',
  manual: 'Corrigé',
}

const formatDuration = (duration?: number): string => {
  if (!duration) return '0h'
  else return formatDurationOriginal(duration)
}

interface SummaryValue {
  key: string
  path?: (accessor: string) => string[]
  accessor?: string
  label: JSX.Element | string
  display: (value: any) => string | React.ReactNode
  valueLabels?: { [any: string]: string }
  tooltip?: SummaryValue[]
  buildTooltip?(workPeriod: WorkPeriodWithSummary): WorkPeriodSummaryValue[]
}

export interface WorkPeriodSummaryValue extends SummaryValue {
  key:
    | 'start.counted'
    | 'start.clocked'
    | 'start.manual'
    | 'start.theoretical'
    | 'start.delay'
    | 'start.lastComment'
    | 'end.counted'
    | 'end.clocked'
    | 'end.manual'
    | 'end.theoretical'
    | 'end.delay'
    | 'end.lastComment'
    | 'breaks[0].start'
    | 'breaks[0].end'
    | 'durations.breaks'
    | 'durations.worked.day'
    | 'durations.worked.night'
    | 'basket'
    | 'isAbandoned'
    | 'events'
    | 'isCancelled'
    | 'validation'
  tooltip?: WorkPeriodSummaryValue[]
}
interface MissionSummaryValue extends SummaryValue {
  key:
    | 'durations.worked.total'
    | 'durations.worked.days'
    | 'durations.worked.nights'
    | 'durations.overtime.total'
    | 'durations.overtime.25'
    | 'durations.overtime.50'
    | 'durations.rc'
    | 'basket'
    | 'costs.worked.total'
    | 'costs.worked.days'
    | 'costs.worked.nights'
    | 'costs.overtime'
    | 'costs.rc'
    | 'costs.total'
    | 'costs.basket'
  tooltip?: MissionSummaryValue[]
}

export const missionSummaryCostsHeaders: MissionSummaryValue[] = [
  {
    key: 'costs.worked.days',
    label: 'Jour',
    display: formatCost,
  },
  {
    key: 'costs.worked.nights',
    label: 'Nuit',
    display: formatCost,
  },
  {
    key: 'costs.overtime',
    label: 'Supp',
    display: formatCost,
  },
  {
    key: 'costs.basket',
    label: 'Panier',
    display: formatCost,
  },
  {
    key: 'costs.rc',
    label: 'RC',
    display: formatCost,
  },
  {
    key: 'costs.total',
    label: 'Semaine',
    display: formatCost,
  },
]

export const missionSummaryTotalsHeaders: MissionSummaryValue[] = [
  {
    key: 'durations.worked.days',
    label: 'Jour',
    display: formatDuration,
  },
  {
    key: 'durations.worked.nights',
    label: 'Nuit',
    display: formatDuration,
  },
  {
    key: 'durations.worked.total',
    label: 'Semaine',
    display: formatDuration,
  },
  {
    key: 'durations.overtime.total',
    label: 'Supp',
    display: formatDuration,
  },
  {
    key: 'durations.overtime.25',
    label: '+25',
    display: formatDuration,
  },
  {
    key: 'durations.overtime.50',
    label: '+50',
    display: formatDuration,
  },
  {
    key: 'basket',
    label: 'Panier',
    display: v => v,
  },
  {
    key: 'durations.rc',
    label: 'RC',
    display: formatDuration,
  },
]

export const workPeriodSummaryHeaders: WorkPeriodSummaryValue[] = [
  {
    key: 'start.counted',
    path: accessor => ['start', accessor],
    accessor: 'start.counted',
    label: 'Début',
    display: formatTime,
    tooltip: [
      {
        key: 'start.counted',
        label: 'Calculé',
        display: v => v,
        valueLabels: countedLabels,
      },
      {
        key: 'start.clocked',
        label: 'Pointage',
        display: formatTime,
      },
      {
        key: 'start.manual',
        label: 'Corrigé',
        display: formatTime,
      },
      {
        key: 'start.theoretical',
        label: 'Théorique',
        display: formatTime,
      },
      {
        key: 'start.lastComment',
        label: 'Commentaire',
        display: comment => <WorkPeriodComment comment={comment} />,
      },
    ],
  },
  {
    key: 'end.counted',
    path: accessor => ['end', accessor],
    accessor: 'end.counted',
    label: 'Fin',
    display: (value: Date): string => {
      return formatTime(value)
    },
    tooltip: [
      {
        key: 'end.counted',
        label: 'Calculé',
        display: v => v,
        valueLabels: countedLabels,
      },
      {
        key: 'end.clocked',
        label: 'Pointage',
        display: formatTime,
      },
      {
        key: 'end.manual',
        label: 'Corrigé',
        display: formatTime,
      },
      {
        key: 'end.theoretical',
        label: 'Théorique',
        display: formatTime,
      },
      {
        key: 'end.lastComment',
        label: 'Commentaire',
        display: comment => <WorkPeriodComment comment={comment} />,
      },
    ],
  },
  {
    key: 'durations.breaks',
    label: 'Pause',
    display: formatDuration,
    buildTooltip: (workPeriod: WorkPeriodWithSummary) => {
      const tooltipsValues: WorkPeriodSummaryValue[] = []
      if (workPeriod.manualBreak) {
        tooltipsValues.push({
          key: `durations.breaks`,
          label: `Pause forcée`,
          display: formatDuration,
        })
      } else
        for (let i = 0; i < workPeriod.summary.breaks.length; i++) {
          tooltipsValues.push({
            key: `breaks[${i}].start`,
            label: `Début pause ${i + 1}`,
            display: formatTime,
          })
          tooltipsValues.push({
            key: `breaks[${i}].end`,
            label: `Fin pause ${i + 1}`,
            display: formatTime,
          })
          tooltipsValues.push({
            key: `breaks[${i}].duration`,
            label: `Durée pause ${i + 1}`,
            display: formatDuration,
          })
        }
      return tooltipsValues
    },
  },
  {
    key: 'durations.worked.day',
    label: 'Jour',
    display: formatDuration,
  },
  {
    key: 'durations.worked.night',
    label: 'Nuit',
    display: formatDuration,
  },
  {
    key: 'basket',
    label: 'Panier',
    display: (value?: number): string => {
      if (value === undefined || value === null) {
        return ''
      } else return value + ''
    },
  },
  {
    key: 'isAbandoned',
    label: (
      <WorkPeriodAbandonedMessage
        workPeriod={{ isAbandoned: true }}
        type="icon"
        small
        iconColor="dark"
      />
    ),
    display: () => 'oops', // Shouldn't be displayed
  },
  {
    key: 'events',
    label: (
      <WorkPeriodEventsMessage
        workPeriod={{ events: [] }}
        type="icon"
        small
        iconColor="dark"
        text="Évènements"
      />
    ),
    display: () => undefined,
  },
  {
    key: 'isCancelled',
    label: (
      <WorkPeriodCancelledMessage
        workPeriod={{ isCancelled: true }}
        type="icon"
        small
        iconColor="dark"
      />
    ),
    display: () => 'oops', // Shouldn't be displayed
  },
  {
    key: 'validation',
    label: 'Valider',
    display: () => 'oops', // Shouldn't be displayed
  },
]

const allSummaryHeaders = [
  ...missionSummaryCostsHeaders,
  ...missionSummaryTotalsHeaders,
  ...workPeriodSummaryHeaders,
]

const getSummaryHeader = (
  summary: MissionWithSummary['summary'] | WorkPeriodWithSummary['summary'],
  key: string,
) => {
  const m = allSummaryHeaders.find(m => m.key === key)
  if (!m) return null
  if (m.accessor && m.path) {
    return _get(summary, m.path(_get(summary, m.accessor)))
  }
  return _get(summary, key)
}

const getWorkPeriodSummaryHeaderColor = (
  mission: Mission,
  workPeriod: WorkPeriodWithSummary,
  workPeriodSummaryHeader: SummaryValue,
) => {
  const red = 'red-validation-dark'
  const green = 'green-validation'
  const yellow = 'orange-validation'
  const purple = 'purple-validation'
  /*
   / Si un évènement type Prestation est attribué à la work period
   / Les heures théoriques sont affichées en mauve
  */
  if (hasBenefitEvent(workPeriod)) {
    return purple
  }

  /*
   / Si la pause est forcée elle est affichée en rouge
  */
  if (workPeriodSummaryHeader.key === 'durations.breaks' && workPeriod.manualBreak) {
    return red
  }

  // Heure de début
  /*
    / Si Heure de Début Corrigée par un Responsable :
    / L’heure de Début Corrigée par un Responsable en rouge
    */
  if (
    workPeriodSummaryHeader.key === 'start.counted' &&
    _get(workPeriod.summary, 'start.counted') === 'manual'
  )
    return red
  /*
    / Pointage d’Entrée sans Heure de Début Corrigée
    / Si Heure prise en compte est l'heure théorique -> vert
    / Sinon on est hors tolérance -> orange
    */
  if (
    workPeriodSummaryHeader.key === 'start.counted' &&
    workPeriod.start.clocking &&
    _get(workPeriod.summary, 'start.counted') === 'clocked'
  )
    return yellow
  if (
    workPeriodSummaryHeader.key === 'start.counted' &&
    workPeriod.start.clocking &&
    _get(workPeriod.summary, 'start.counted') === 'theoretical'
  )
    return green

  // Heure de fin
  /*
    / Si Heure de Fin Corrigée par un Responsable :
    / L’heure de Fin Corrigée par un Responsable en rouge
    */
  if (
    workPeriodSummaryHeader.key === 'end.counted' &&
    _get(workPeriod.summary, 'end.counted') === 'manual'
  )
    return red
  /*  
    / Pointage de Sortie sans Heure de Fin Corrigée :
    / Si Heure prise en compte est l'heure théorique -> vert
    / Sinon on est hors tolérance -> orange
    */
  if (
    workPeriodSummaryHeader.key === 'end.counted' &&
    workPeriod.end.clocking &&
    _get(workPeriod.summary, 'end.counted') === 'clocked'
  )
    return yellow
  if (
    workPeriodSummaryHeader.key === 'end.counted' &&
    workPeriod.end.clocking &&
    _get(workPeriod.summary, 'end.counted') === 'theoretical'
  )
    return green
  /*  
    / Si pas de Pointage de Sortie ou d’Heure de Fin Corrigée
    / Pas possible de déterminer l’Heure de Fin, le Responsable doit créer une Heure de Fin Corrigée pour moi, dans ce cas, heure de fin théorique en orange, mais impossibilité de valider le pointage sans validation d’un responsable.
    */
  if (
    workPeriodSummaryHeader.key === 'end.counted' &&
    workPeriod.start.clocking &&
    !workPeriod.end.clocking
  )
    return yellow

  return undefined
}

export const WorkPeriodSummaryValue = ({
  mission,
  workPeriod,
  workPeriodSummaryHeader,
}: {
  mission: Mission
  workPeriod: WorkPeriodWithSummary
  workPeriodSummaryHeader: SummaryValue
}): JSX.Element => {
  const type = workPeriodSummaryHeader.key === 'start.counted' ? 'start' : 'end'
  const benefitEvents = getEventsByType(workPeriod, 'benefit')
  const workPeriodHasBenefitEvent = Boolean(benefitEvents[0])
  const value = React.useMemo(
    () => getSummaryHeader(workPeriod.summary, workPeriodSummaryHeader.key),
    [workPeriod, workPeriodSummaryHeader],
  )
  const color = React.useMemo(
    () => getWorkPeriodSummaryHeaderColor(mission, workPeriod, workPeriodSummaryHeader),
    [mission, workPeriod, workPeriodSummaryHeader],
  )
  const tooltipContent = React.useMemo(() => {
    const tooltipData = workPeriodSummaryHeader.buildTooltip
      ? workPeriodSummaryHeader.buildTooltip(workPeriod)
      : workPeriodSummaryHeader.tooltip
    return tooltipData?.map(workPeriodSummaryValue => {
      const label = workPeriodSummaryValue.label
      let value = workPeriodSummaryValue.display(
        _get(workPeriod.summary, workPeriodSummaryValue.key),
      )
      if (workPeriodSummaryValue.valueLabels && workPeriodSummaryValue.valueLabels[value]) {
        value = workPeriodSummaryValue.valueLabels[value]
      }
      return {
        label: label,
        value: value || '/',
      }
    })
  }, [workPeriodSummaryHeader, workPeriod])
  const tooltipId = mission._id + workPeriod._id + workPeriodSummaryHeader.key
  const [isOver, setIsOver] = React.useState(false)

  return (
    <>
      {isAuthorizedFor(['employerMember', 'interimAgencyMember']) && tooltipContent && isOver && (
        <ReactTooltip id={tooltipId} place="bottom">
          <div style={{ display: 'table' }}>
            {tooltipContent.map(({ label, value }) => {
              return (
                <Element py={1} key={label} style={{ display: 'table-row' }}>
                  <Element style={{ display: 'table-cell' }} px={1}>
                    {label}
                  </Element>
                  <Element style={{ display: 'table-cell' }} px={1}>
                    {value}
                  </Element>
                </Element>
              )
            })}
          </div>
          --- <br />
          Règle de Pointage {workPeriod.clockingRules.name}:
          <ClockingRulesDescription clockingRules={workPeriod.clockingRules} type={type} />
          {workPeriodHasBenefitEvent && (
            <Element py={1} key={'benefitEvents'} style={{ display: 'table-row' }}>
              <Element style={{ display: 'table-cell' }} px={1}>
                Evén. Prestation
              </Element>
              <Element style={{ display: 'table-cell' }} px={1} textWeight="bold">
                {benefitEvents.map(e => e.item.code).join(', ')}
              </Element>
            </Element>
          )}
        </ReactTooltip>
      )}
      <Element
        renderAs={isAuthorizedFor(['employerMember']) ? Link : 'span'}
        to={
          isAuthorizedFor(['employerMember']) &&
          `/missions/${mission._id}/work-periods/${workPeriod._id}`
        }
        data-tip
        data-for={tooltipId}
        textColor={color || 'dark'}
        textWeight={color ? 'bold' : 'normal'}
        onMouseOver={() => setIsOver(true)}
        onMouseOut={() => setIsOver(false)}
        style={
          workPeriod.isAbandoned || workPeriod.isCancelled
            ? { textDecoration: 'line-through', textDecorationThickness: '1px' }
            : {}
        }
      >
        {value !== undefined && !workPeriodHasBenefitEvent ? (
          workPeriodSummaryHeader.display(value)
        ) : (
          <>-&nbsp;h&nbsp;-</>
        )}
      </Element>
    </>
  )
}

const MissionSummaryValue = ({
  mission,
  missionSummaryHeader,
}: {
  mission: MissionWithSummary
  missionSummaryHeader: MissionSummaryValue
}) => {
  const value = React.useMemo(
    () => getSummaryHeader(mission.summary, missionSummaryHeader.key),
    [mission, missionSummaryHeader],
  )

  return <>{value !== undefined ? missionSummaryHeader.display(value) : <>-&nbsp;h&nbsp;-</>}</>
}

interface WorkPeriodSummaryCellProps {
  data: any
  day: Date
  workPeriodSummaryHeader: SummaryValue
  validationAction?: (workPeriod: WorkPeriod) => void
}

export const WorkPeriodSummaryCell: React.FunctionComponent<WorkPeriodSummaryCellProps> = ({
  data,
  day,
  workPeriodSummaryHeader,
  validationAction,
}) => {
  const mission: Mission = data.cell.row.original
  const workPeriod = React.useMemo(() => {
    return getWorkPeriodFromDayDate(mission.workPeriods, new Date(day)) as
      | WorkPeriodWithSummary
      | undefined
  }, [mission])
  // There is NO WorkPeriod today for the user in his Mission
  if (!workPeriod || !workPeriod.summary) return null
  // There is a WorkPeriod today
  //return _.get(workPeriod.summary, workPeriodSummaryHeader.key) || null
  if (workPeriodSummaryHeader.key === 'validation') {
    return (
      <StandardCell>
        <Protected
          roles={['employerMember']}
          action={{ resource: 'missions', name: 'validateWorkPeriod' }}
        >
          <WorkPeriodValidateButton
            workPeriod={workPeriod}
            label=""
            type="button"
            size="smallest"
            onClick={workPeriod => validationAction && validationAction({ ...workPeriod, mission })}
          />
        </Protected>
      </StandardCell>
    )
  }
  if (workPeriodSummaryHeader.key === 'events') {
    return (
      <StandardCell>
        {workPeriod.events[0] && (
          <WorkPeriodEventsMessage small type="icon" workPeriod={workPeriod} />
        )}
      </StandardCell>
    )
  }
  if (workPeriodSummaryHeader.key === 'isAbandoned') {
    return (
      <StandardCell>
        <WorkPeriodAbandonedMessage small type="icon" workPeriod={workPeriod} />
      </StandardCell>
    )
  }
  if (workPeriodSummaryHeader.key === 'isCancelled') {
    return (
      <StandardCell>
        <WorkPeriodCancelledMessage small type="icon" workPeriod={workPeriod} />
      </StandardCell>
    )
  }
  return (
    <StandardCell>
      <WorkPeriodSummaryValue
        mission={mission}
        workPeriod={workPeriod}
        workPeriodSummaryHeader={workPeriodSummaryHeader}
      />
    </StandardCell>
  )
}

interface MissionSummaryCellProps {
  data: any
  missionSummaryHeader: MissionSummaryValue
}

export const MissionSummaryCell: React.FunctionComponent<MissionSummaryCellProps> = ({
  data,
  missionSummaryHeader,
}) => {
  const mission: MissionWithSummary = data.cell.row.original
  if (!mission.summary) return null
  else {
    return (
      <StandardCell>
        <MissionSummaryValue mission={mission} missionSummaryHeader={missionSummaryHeader} />
      </StandardCell>
    )
  }
}

const WorkPeriodComment: React.FC<{ comment: WorkPeriodWithSummaryComment }> = ({
  comment,
}) => (
  <>
    {comment.message} <br />
    {comment.user && ( //TODO: create component for user name
      <Element>
        <Icon size="small" style={{ verticalAlign: 'middle' }} mr={1}>
          <UserCircleIcon />
        </Icon>
        <Avatar firstName={comment.user?.firstName} lastName={comment.user?.lastName} />
      </Element>
    )}
  </>
)
