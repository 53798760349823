import React from 'react'
import { Container, Level, Section, Tag } from 'react-bulma-components'
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs'
import FormWrapper from '../../components/form/form-wrapper'
import Layout from '../../components/layout/layout'
import WorkersRequestFormTitle from '../../components/workers-requests/titles/workers-request-form-title'
import { ViewActions } from '../../components/workers-requests/titles/workers-request-page-title'
import WorkersRequestForm from '../../components/workers-requests/workers-request-form'
import WorkersRequestsMessages from '../../components/workers-requests/workers-requests-messages'
import { useFormWrapperPropsWithMutationData } from '../../hooks/use-form-wrapper-props-with-mutation-data'
import useUrlId from '../../hooks/use-url-id'
import { useMutationState } from '../../queries'
import { useCreateMutationKey, useWorkersRequestQuery } from '../../queries/workers-requests'

const WorkersRequestsEdition: React.FC = ({}) => {
  const mutationState = useMutationState(useCreateMutationKey())
  const { id, isNew } = useUrlId()
  const { data: workersRequest } = useWorkersRequestQuery(id)
  const formWrapperProps = useFormWrapperPropsWithMutationData(
    formWrapperBaseProps,
    mutationState,
    !isNew,
  )

  const breadcrumbs = useBreadcrumbs(isNew, id)

  return (
    <Layout>
      <Section>
        <FormWrapper {...formWrapperProps}>
          <Container breakpoint={'desktop'} max>
            <Level>
              <Level.Side>
                <Breadcrumbs items={breadcrumbs} />
              </Level.Side>
              <Level.Side align="right">
                {workersRequest && <ViewActions workersRequest={workersRequest} />}
              </Level.Side>
            </Level>
            <Level alignItems="flex-start" mb={6}>
              <Level.Side>
                <WorkersRequestFormTitle workersRequest={workersRequest} isNew={isNew} />
              </Level.Side>
              <Level.Side align="right">
                <Tag.Group>
                  {workersRequest && (
                    <WorkersRequestsMessages workersRequest={workersRequest} type="tag" />
                  )}
                </Tag.Group>
              </Level.Side>
            </Level>
            <WorkersRequestForm workersRequest={workersRequest} />
          </Container>
        </FormWrapper>
      </Section>
    </Layout>
  )
}

export default WorkersRequestsEdition

const formWrapperBaseProps = {
  loadingProps: { body: 'Enregistrement de la Demande...' },
  successProps: {
    title: 'Demande créée',
    body: {
      description: "La Demande a été créée, l'Agence d'intérim a été Notifiée.",
      actions: [
        {
          label: 'Nouvelle Demande',
        },
        {
          label: 'Retourner à la liste',
          to: '/workers-requests',
        },
      ],
    },
  },
}

const useBreadcrumbs = (isNew?: boolean, id?: string) =>
  React.useMemo(() => {
    const breadcrumbs = [
      {
        label: "Demandes d'Intérimaires",
        to: '/workers-requests',
      },
      {
        label: isNew ? "Nouvelle Demande d'Intérimaires" : `Modifier`,
      },
    ]

    if (!isNew && id)
      breadcrumbs.splice(1, 0, {
        label: "Demande d'Intérimaires",
        to: `/workers-requests/${id}`,
      })

    return breadcrumbs
  }, [isNew, id])
