import { produce } from 'immer'
import { StoreSlice, StoreState } from '.'
import { endOfWeek, startOfWeek } from '../utils/date'
import { Organization } from '../api/organizations'
import { JobTitle } from '../api/job-titles'

export enum FiltersResourcesEnum {
  missions = 'missions',
  workersRequests = 'workersRequests',
  clockings = 'clockings',
  preBillings = 'preBillings',
}

export interface MissionsFilters {
  start?: Date
  end?: Date
  service?: string[]
  interimAgency?: Organization['_id'][]
  employer?: Organization['_id'][]
  displayDay?: Date
  jobTitle?: JobTitle['_id'][]
  summaryMinimalMode?: 'minimal'
  user?: string[]
  isValidated?: boolean
  isCancelled?: boolean[]
  'workPeriods.preBilling'?: string[] | boolean[]
  preBilling?: string[] | boolean[]
  workPeriodType?: 'coming' | 'started' | 'ended'
}

export interface WorkersRequestsFilters {
  service?: MissionsFilters['service']
  interimAgency?: MissionsFilters['interimAgency']
  jobTitle?: MissionsFilters['jobTitle']
  employer?: MissionsFilters['employer']
  isCancelled?: boolean[]
  isComplete?: boolean[]
  isExpired?: boolean[]
  start?: Date
  end?: Date
}

export interface ClockingsFilters {
  user?: MissionsFilters['user']
  start?: MissionsFilters['start']
  end?: MissionsFilters['end']
}

export interface PreBillingsFilters {
  start?: MissionsFilters['start']
  end?: MissionsFilters['end']
  interimAgency?: MissionsFilters['interimAgency']
  invoiceRef?: boolean[]
}

interface PaginationItem {
  currentPage: number
}

interface Pagination {
  summary: PaginationItem
}

export interface FiltersSlice {
  [FiltersResourcesEnum.missions]: MissionsFilters
  [FiltersResourcesEnum.workersRequests]: WorkersRequestsFilters
  [FiltersResourcesEnum.clockings]: ClockingsFilters
  [FiltersResourcesEnum.preBillings]: PreBillingsFilters
  setFilters(resource: FiltersResourcesEnum.missions, value: MissionsFilters): void
  setFilters(resource: FiltersResourcesEnum.workersRequests, filters: WorkersRequestsFilters): void
  setFilters(resource: FiltersResourcesEnum.clockings, filters: ClockingsFilters): void
  setFilters(resource: FiltersResourcesEnum.preBillings, filters: PreBillingsFilters): void
  setFilter(resource: FiltersResourcesEnum, path: string, value: any): void
  pagination: Pagination
  setPagination(item: keyof Pagination, value: PaginationItem): void
}

const now = new Date()
const initialStartEnd = { start: startOfWeek(now), end: endOfWeek(now) }

const filtersSlice: StoreSlice<FiltersSlice> = set => ({
  [FiltersResourcesEnum.missions]: {
    ...initialStartEnd,
    preBilling: [false],
    isCancelled: [false],
    summaryMinimalMode: 'minimal',
  },
  [FiltersResourcesEnum.workersRequests]: {
    isExpired: [false],
    isComplete: [false],
    isCancelled: [false],
  },
  [FiltersResourcesEnum.clockings]: {
    ...initialStartEnd,
  },
  [FiltersResourcesEnum.preBillings]: {
    invoiceRef: [false],
  },
  pagination: {
    summary: { currentPage: 1 },
  },
  setFilter: (resource, key, value) => {
    set(
      produce(state => {
        state.filters[resource][key] = value
      }),
    )
  },
  setFilters: (resource, filters) => {
    set(
      produce(state => {
        state.filters[resource] = filters
      }),
    )
  },
  setPagination: (item, value) => {
    set(
      produce((state: StoreState) => {
        state.filters.pagination[item] = value
      }),
    )
  },
})

export default filtersSlice
