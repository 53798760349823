import React from 'react'
import { Form, Panel, Columns, Container, Element, Icon } from 'react-bulma-components'
import ComponentHeader from '../sections/component-header'
import { useOrganizationsQuery } from '../../queries/organizations'
import useStore from '../../store'
import { InterimAgency } from '../../api/organizations'
import { formatDuration, formatTime, getWeek, localDate } from '../../utils/date'
import { formatCost } from '../../utils/finance'
import { MissionWithSummary } from '../../api/missions'
import { PreBilling } from '../../api/pre-billings'
import { useSmicQuery } from '../../queries/smic'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'

interface SummaryInfoPanelProps {
  title: string
  data: {
    label: string
    value: string | number | React.ReactNode
  }[]
}
const SummaryInfoPanel: React.FunctionComponent<SummaryInfoPanelProps> = ({ title, data }) => {
  return (
    <Panel style={{ minWidth: 200, boxShadow: 'none' }}>
      <Panel.Header textSize={6} backgroundColor={'white'}>
        {title}
      </Panel.Header>
      {data.map(({ label, value }) => (
        <Panel.Block key={label + value}>
          <Form.Control display="flex" justifyContent="space-between">
            <Element textWeight="bold" textSize={7} mr={3}>
              {label}
            </Element>
            <Element textSize={7}>{value}</Element>
          </Form.Control>
        </Panel.Block>
      ))}
    </Panel>
  )
}

interface SummaryHeaderProps {
  missions: MissionWithSummary[]
  startOfWeek: Date
  endOfWeek: Date
  interimAgencyId?: string
  forceCompleteMode?: boolean
  //service?: string
  // jobTitle?: string
  totals?: {
    // TODO: use PreBilling['missions']['totals'] => need backend who pre-calculate PreBilling totals
    durations: {
      worked: Pick<PreBilling['missions']['totals']['durations']['worked'], 'days' | 'nights'>
      overtime: Pick<PreBilling['missions']['totals']['durations']['overtime'], '25' | '50'>
    }
    costs: {
      worked: Pick<PreBilling['missions']['totals']['costs']['worked'], 'days' | 'nights'>
      overtime: PreBilling['missions']['totals']['costs']['overtime']
      rc: PreBilling['missions']['totals']['costs']['rc']
      basket: PreBilling['missions']['totals']['costs']['basket']
      total: PreBilling['missions']['totals']['costs']['total']
    }
  }
}

const SummaryHeader: React.FunctionComponent<SummaryHeaderProps> = ({
  missions,
  startOfWeek,
  endOfWeek,
  interimAgencyId,
  // service,
  totals,
  // jobTitle,
  forceCompleteMode,
}) => {
  const organizationsQuery = useOrganizationsQuery(
    { type: 'interimAgency' },
    { staleTime: 1000 * 60 * 2 },
  )
  const [isSmallMode, setIsSmallMode] = React.useState(forceCompleteMode ? false : true)
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const currentInterimAgency = React.useMemo(
    () =>
      (currentOrganization && currentOrganization.type === 'interimAgency'
        ? currentOrganization
        : (organizationsQuery.data || []).find(
            current => current._id === interimAgencyId,
          )) as InterimAgency,
    [organizationsQuery.data, interimAgencyId],
  )

  const { data } = useSmicQuery(new Date(startOfWeek as Date), {
    enabled: Boolean(startOfWeek) && !isSmallMode,
  })
  const baseLaborHourlyCost = data?.value || 0

  const endOfWeekSmicQuery = useSmicQuery(new Date(endOfWeek as Date), {
    enabled: Boolean(endOfWeek) && !isSmallMode,
  })

  const ChangeModeButton: React.FC = () => {
    if (forceCompleteMode) return <></>
    return (
      <Element textAlign={'right'}>
        <Element renderAs="a" onClick={() => setIsSmallMode(!isSmallMode)} textSize={7}>
          <Icon.Text size={'small'}>
            {isSmallMode ? 'Afficher les détails' : 'Masquer les détails'}
            <Icon>{isSmallMode ? <ChevronDownIcon /> : <ChevronUpIcon />}</Icon>
          </Icon.Text>
        </Element>
      </Element>
    )
  }

  if (isSmallMode)
    return (
      <Columns>
        <Columns.Column size={4} m="auto">
          <Element renderAs="strong">
            Semaine {getWeek(startOfWeek)} - Du {localDate(startOfWeek)} au {localDate(endOfWeek)}
          </Element>
        </Columns.Column>
        <Columns.Column size={4} textAlign={'center'}></Columns.Column>
        <Columns.Column size={4} textAlign={'right'}>
          TOTAL HT:
          <Element renderAs="strong"> {formatCost(totals?.costs?.total)}</Element>
          <ChangeModeButton />
        </Columns.Column>
      </Columns>
    )

  return (
    <Container breakpoint={'fluid'}>
      <ComponentHeader>
        <ComponentHeader.Left>
          <Columns backgroundColor="white-bis">
            <Columns.Column narrow>
              <SummaryInfoPanel
                title="Informations"
                data={[
                  { label: 'Du', value: localDate(startOfWeek) },
                  { label: 'Au', value: localDate(endOfWeek) },
                  { label: 'Semaine', value: getWeek(startOfWeek) },
                  //{ label: 'Service', value: service ? service : 'tous' },
                  {
                    label: 'Agence',
                    value: currentInterimAgency ? currentInterimAgency.name : 'tous',
                  },
                  {
                    label: 'SMIC',
                    value: (
                      <Element textAlign={'right'}>
                        {baseLaborHourlyCost}€{' '}
                        {endOfWeekSmicQuery.data?.value &&
                        endOfWeekSmicQuery.data.value !== baseLaborHourlyCost ? (
                          <>
                            <br />
                            {endOfWeekSmicQuery.data.value}€ au{' '}
                            {localDate(new Date(endOfWeekSmicQuery.data.from))}
                          </>
                        ) : (
                          ''
                        )}
                      </Element>
                    ),
                  },
                ]}
              />
            </Columns.Column>
            <Columns.Column narrow>
              <SummaryInfoPanel
                title={'Données'}
                data={[
                  { label: 'Panier nuit', value: formatCost(7.1) },
                  {
                    label: 'Heure début nuit',
                    value: formatTime(new Date().setHours(21, 0, 0, 0)),
                  },
                  {
                    label: 'Heure fin nuit',
                    value: formatTime(new Date().setHours(5, 0, 0, 0)),
                  },
                  {
                    label: 'Heure pause',
                    value: formatTime(new Date().setHours(17, 30, 0, 0)),
                  },
                  {
                    label: 'Nbr de missions',
                    value: missions.length,
                  },
                ]}
              />
            </Columns.Column>

            <Columns.Column narrow>
              <SummaryInfoPanel
                title={'Totaux heures'}
                data={[
                  {
                    label: 'Jour',
                    value: formatDuration(totals?.durations?.worked.days || 0),
                  },
                  {
                    label: 'Nuit',
                    value: formatDuration(totals?.durations?.worked.nights || 0),
                  },
                  {
                    label: 'Heures Sup. 25%',
                    value: formatDuration(totals?.durations?.overtime[25] || 0),
                  },
                  {
                    label: 'Heures Sup. 50%',
                    value: formatDuration(totals?.durations?.overtime[50] || 0),
                  },
                ]}
              />
            </Columns.Column>
            <Columns.Column narrow>
              <SummaryInfoPanel
                title={'Totaux coûts'}
                data={[
                  {
                    label: 'Heures jour base 35',
                    value: formatCost(totals?.costs?.worked?.days),
                  },
                  {
                    label: 'Heures nuit',
                    value: formatCost(totals?.costs?.worked?.nights),
                  },
                  {
                    label: 'Heures Sup.',
                    value: formatCost(totals?.costs?.overtime),
                  },
                  {
                    label: 'RC',
                    value: formatCost(totals?.costs?.rc),
                  },
                  {
                    label: 'Panier',
                    value: formatCost(totals?.costs?.basket),
                  },
                  {
                    label: 'Total HT',
                    value: <Element renderAs="strong">{formatCost(totals?.costs?.total)}</Element>,
                  },
                ]}
              />
            </Columns.Column>
          </Columns>
        </ComponentHeader.Left>
        <ComponentHeader.Right>
          <Columns>
            {currentInterimAgency && (
              <Columns.Column narrow>
                <SummaryInfoPanel
                  title={currentInterimAgency.name}
                  data={[
                    { label: 'Coefficient', value: currentInterimAgency.laborCostCoefficient },
                    {
                      label: 'Coût horaire',
                      value: formatCost(
                        baseLaborHourlyCost * currentInterimAgency.laborCostCoefficient,
                      ),
                    },
                    {
                      label: 'Coût heure +25%',
                      value: formatCost(
                        baseLaborHourlyCost * currentInterimAgency.laborCostCoefficient * 1.25,
                      ),
                    },
                    {
                      label: 'Coût heure +50%',
                      value: formatCost(
                        baseLaborHourlyCost * currentInterimAgency.laborCostCoefficient * 1.5,
                      ),
                    },
                  ]}
                />
              </Columns.Column>
            )}
          </Columns>
        </ComponentHeader.Right>
      </ComponentHeader>
      <ChangeModeButton />
    </Container>
  )
}

export default SummaryHeader
