import useStore from '../store/index'
import {
  ClockingsFilters,
  FiltersResourcesEnum,
  FiltersSlice,
  MissionsFilters,
  PreBillingsFilters,
  WorkersRequestsFilters,
} from '../store/filters'

interface UseFiltersHook<T> {
  filters: T
  setFilters: FiltersSlice['setFilters']
  setFilter: FiltersSlice['setFilter']
}

function useFilters(
  resource: FiltersResourcesEnum.workersRequests,
): UseFiltersHook<WorkersRequestsFilters>
function useFilters(resource: FiltersResourcesEnum.missions): UseFiltersHook<MissionsFilters>
function useFilters(resource: FiltersResourcesEnum.clockings): UseFiltersHook<ClockingsFilters>
function useFilters(resource: FiltersResourcesEnum.preBillings): UseFiltersHook<PreBillingsFilters>
function useFilters(resource: FiltersResourcesEnum): UseFiltersHook<any> {
  const filters = useStore(state => state.filters[resource])
  const setFilters = useStore(state => state.filters.setFilters)
  const setFilter = useStore(state => state.filters.setFilter)

  return { filters, setFilters, setFilter }
}

export default useFilters
