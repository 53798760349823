import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Columns, Container, Element, Heading } from 'react-bulma-components'
import { useCreateMutation, useUpdateMutation } from '../../queries/organizations'
import { Employer, OrganizationTypeEnum } from '../../api/organizations'
import InputField from '../form/fields/input'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import SelectField from '../form/fields/select'
import { AddressesForm } from './addresses-form'
import Protected from '../protected/protected'
import CheckboxField from '../form/fields/checkbox'

interface EmployerFormProps {
  organization?: Employer
}

const EmployerForm: React.FunctionComponent<EmployerFormProps> = ({ organization }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  return (
    <Formik
      initialValues={{
        name: organization?.name || '',
        type: OrganizationTypeEnum.employer,
        rules: {
          missions: {
            abandonmentPayments: Boolean(organization?.rules?.missions.abandonmentPayments),
          },
        },
        addresses: organization?.addresses || [],
        appOptions: organization?.appOptions,
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.name) errors.name = 'Champ requis'
        if (values.addresses.length === 0)
          errors.addresses = 'Au moins une adresse doit être renseignée'
        return errors
      }}
      onSubmit={values => {
        if (!organization) createMutation.mutate(values)
        else updateMutation.mutate({ id: organization._id.toString(), ...values })
      }}
    >
      {() => {
        return (
          <Form>
            <Columns>
              <Columns.Column size={12}>
                <Field label="Nom de l'Employeur" name="name" component={InputField} required />
                <AddressesForm />
              </Columns.Column>
              <Columns.Column size={12}>
                <Field
                  label="Les Abandons de Poste sont"
                  name="rules.missions.abandonmentPayments"
                  component={SelectField}
                  options={[
                    { value: true, label: 'Payés' },
                    { value: false, label: 'Non Payés' },
                  ]}
                />
              </Columns.Column>
              <Protected roles={['superAdmin']}>
                <Columns.Column size={12}>
                  <Heading size={4} mt={5}>
                    Fonctionnalités disponnibles
                  </Heading>
                  <Field label="Bi-parti" name="appOptions.biPart" component={CheckboxField} />
                </Columns.Column>
              </Protected>
            </Columns>
            <Container>
              <Element mb={1}>
                <RequestMessage mutation={updateMutation} />
              </Element>
              <RequestButton color="primary" type="submit" mutation={updateMutation}>
                {organization
                  ? "Mettre à jour les données de l'Employeur"
                  : 'Créer un Nouvel Employeur'}
              </RequestButton>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EmployerForm
