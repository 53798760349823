import React from 'react'
import { Employer, InterimAgency } from '../api/organizations'
import useStore from '../store'

//TEMP: to remove when backend access control will be done
//NEED other approach of InterimAgency & Employer associations (need fetch all organizations for now)

const useAssociatedOrganizationsQueryParams = (): any => {
  const currentUserRole = useStore(state => state.session.currentUserRole)
  const currentOrganization = useStore(state => state.session.currentOrganization)
  return React.useMemo(() => {
    if (currentOrganization && currentUserRole === 'employerMember') {
      const employerInterimAgenciesIds = (currentOrganization as Employer).associations
        .filter(association => association.status === 'accepted')
        .map(association =>
          typeof association.interimAgency === 'string' // TODO: remove this type check (inconsistency between currentOragnization of user and currentOrganization of super admin logged as ...)
            ? association.interimAgency
            : association.interimAgency._id,
        )
      if (employerInterimAgenciesIds.length === 0) {
        // Current employer is still not associated with any interim agency
        return {
          type: 'interimAgency',
          // quickfix to not return anything, we know for sure that the employer is not an interim agency
          _id: currentOrganization._id,
        }
      }
      return {
        type: 'interimAgency',
        _id: employerInterimAgenciesIds,
      }
    } else if (currentOrganization && currentUserRole === 'interimAgencyMember')
      return {
        type: 'employer',
        'associations.interimAgency': (currentOrganization as InterimAgency)._id,
        'associations.status': 'accepted',
      }
    return {}
  }, [currentUserRole, currentOrganization])
}

export default useAssociatedOrganizationsQueryParams
