import React from 'react'
import { Button, Heading, Icon } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { WorkersRequest } from '../../../api/workers-requests'
import { EditIcon, ViewIcon, WorkersRequestCancelIcon } from '../../icons'
import PageTitle from '../../pages/page-title'
import Protected from '../../protected/protected'
import WorkersRequestCancelConfirmationModal from '../modals/cancel-confirmation'
import { useModal } from '../../modal'

interface WorkersRequestPageTitleProps {
  workersRequest: WorkersRequest
  organizationType: 'interimAgency' | 'employer'
}

const WorkersRequestPageTitle: React.FC<WorkersRequestPageTitleProps> = ({
  workersRequest,
  organizationType,
}) => {
  return (
    <PageTitle
      title={workersRequest[organizationType].name + ' / ' + workersRequest.missionData.service}
      subTitle={
        <Heading size={5}>
          Postes restant à pourvoir : {workersRequest.stats.notAssigned}&nbsp;/&nbsp;
          {workersRequest.stats.notAssigned + workersRequest.stats.assigned}
        </Heading>
      }
    />
  )
}
export default WorkersRequestPageTitle

export const EditActions: React.FC<Pick<WorkersRequestPageTitleProps, 'workersRequest'>> = ({
  workersRequest,
}) => {
  const modal = useModal()

  return (
    <Protected roles={['employerMember']} action={{ resource: 'workersRequests', name: 'update' }}>
      <Button
        mr={2}
        size="small"
        color={'danger'}
        onClick={() => modal.setIsDisplayed(true)}
        disabled={!workersRequest.__actions.canBeCancelled}
      >
        <Icon mr={1}>
          <WorkersRequestCancelIcon />
        </Icon>
        Annuler la demande
      </Button>
      <WorkersRequestCancelConfirmationModal
        show={modal.isDisplayed}
        hide={() => modal.setIsDisplayed(false)}
        workersRequest={workersRequest}
      />
      <Button
        color={'link'}
        outlined
        renderAs={Link}
        size="small"
        to={`/workers-requests/${workersRequest._id}/edit`}
      >
        <Icon mr={1}>
          <EditIcon />
        </Icon>
        Modifier la demande
      </Button>
    </Protected>
  )
}

export const ViewActions: React.FC<Pick<WorkersRequestPageTitleProps, 'workersRequest'>> = ({
  workersRequest,
}) => {
  return (
    <Protected roles={['employerMember']}>
      <Button
        color={'link'}
        outlined
        renderAs={Link}
        size="small"
        to={`/workers-requests/${workersRequest._id}`}
      >
        <Icon mr={1}>
          <ViewIcon />
        </Icon>
        Voir la demande
      </Button>
    </Protected>
  )
}
