import React from 'react'
import FieldColumn from '../field-column'
import { Field } from 'formik'
import SelectField from '../../form/fields/select'

const WorkPeriodTypesFilterField: React.FC = ({}) => {
  return (
    <FieldColumn>
      <Field
        label="Type de Journée"
        name="workPeriodType"
        component={SelectField}
        options={[
          {
            label: 'Toutes',
            value: null,
          },
          {
            label: 'À prester',
            value: 'coming',
          },
          {
            label: 'Démarrée',
            value: 'started',
          },
          {
            label: 'Terminée',
            value: 'ended',
          },
        ]}
      />
    </FieldColumn>
  )
}

export default WorkPeriodTypesFilterField
