import { addHours, isToday, startOfDay, startOfHour } from 'date-fns'
import { endOfDay } from 'date-fns/esm'
import { cloneDeep } from 'lodash'
import React, { useMemo } from 'react'
import { Card, Columns, Container, Element, Heading, Section } from 'react-bulma-components'
import { AxisOptions, Chart, UserSerie } from 'react-charts'
import { MissionsDashboardCard } from '../../components/dashboards/missions-dashboard-card'
import { WorkPeriodsDashboardCard } from '../../components/dashboards/work-periods-dashboard'
import { WorkersRequestsDashboardCard } from '../../components/dashboards/workers-request-card'
import Layout from '../../components/layout/layout'
import PageHeading from '../../components/sections/page-heading'
import locales from '../../../../locales'
import useStore from '../../store'
import { formatTime } from '../../utils/date'
import QrScannerPage from '../qr-scanner/qr-scanner'
import { useWorkPeriodsQuery } from '../../queries/work-periods'

interface ClockingsPerHour {
  date: Date
  count: number | null
}

const getBaseInterval = (): ClockingsPerHour[] => {
  const today = startOfDay(new Date())
  const baseInterval = Array.from(Array(24).keys()).map(index => {
    const hour = startOfHour(addHours(new Date(today), index))
    return hour
  })
  return baseInterval.map(date => ({ date: date, count: null }))
}

const reducer = (acc: ClockingsPerHour[], currentDate: Date) => {
  const existingIndex = acc.findIndex(value => {
    return value.date.toString() === currentDate.toString()
  })
  const newAcc = cloneDeep(acc)
  if (existingIndex > -1) {
    newAcc[existingIndex] = {
      date: newAcc[existingIndex].date,
      count: (newAcc[existingIndex].count ?? 0) + 1,
    }
    return newAcc
  }
  newAcc.push({
    date: currentDate,
    count: 1,
  })
  return newAcc
}

const EmployerDashboard: React.FC = () => {
  const currentOrganization = useStore(state => state.session.currentOrganization)
  const currentOrganizationRole = useStore(state => state.session.currentOrganizationRole)
  const workPeriodsQuery = useWorkPeriodsQuery({
    populate: [],
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
    fields: ['_id', 'workPeriods'],
    isCancelled: false,
  })
  const workPeriodsToday = workPeriodsQuery?.data?.filter(workPeriod =>
    isToday(new Date(workPeriod?.start.date)),
  )
  const workPeriodsStartsPerHourInterval: Date[] = useMemo(() => {
    return (
      workPeriodsToday
        ?.map(wp => {
          const clockingDate = wp.start.manualClocking ?? wp.start.clocking?.date
          if (clockingDate) {
            return startOfHour(new Date(clockingDate))
          }
          return undefined
        })
        .flatMap(c => (c ? [c] : [])) ?? []
    )
  }, [workPeriodsToday])

  const workPeriodsEndsPerHourInterval: Date[] = useMemo(() => {
    return (
      workPeriodsToday
        ?.map(wp => {
          const clockingDate = wp.end.manualClocking ?? wp.end.clocking?.date
          if (clockingDate) {
            return startOfHour(new Date(clockingDate))
          }
          return undefined
        })
        .flatMap(c => (c ? [c] : [])) ?? []
    )
  }, [workPeriodsToday])
  const agreggateWpStarts = useMemo(() => {
    return workPeriodsStartsPerHourInterval?.reduce<ClockingsPerHour[]>(reducer, getBaseInterval())
  }, [workPeriodsToday])
  const agreggateWpEnds = useMemo(() => {
    return workPeriodsEndsPerHourInterval?.reduce<ClockingsPerHour[]>(reducer, getBaseInterval())
  }, [workPeriodsToday])

  const data: UserSerie<ClockingsPerHour>[] = [
    {
      label: 'Entrées',
      data: agreggateWpStarts,
    },
    {
      label: 'Sorties',
      data: agreggateWpEnds,
    },
  ]
  const primaryAxis = React.useMemo(
    (): AxisOptions<ClockingsPerHour> => ({
      getValue: blabla => blabla.date,
      min: startOfDay(new Date()),
      max: endOfDay(new Date()),
      scaleType: 'localTime',
      formatters: {
        scale: date => (date ? date.toLocaleTimeString() : ''),
        cursor: date => (date ? new Date(date).toLocaleTimeString() : ''),
        tooltip: date => (
          <Element textColor="white" textSize={7}>
            {formatTime(new Date(date))}
          </Element>
        ),
      },
    }),
    [],
  )
  const secondaryAxes = React.useMemo(
    (): AxisOptions<ClockingsPerHour>[] => [
      {
        getValue: blabla => blabla.count,
        min: 0,
        max: workPeriodsToday?.length ?? 1,
        formatters: {
          scale: (count: number) => count + '',
          tooltip: (count: number) => (count ?? 0) + '',
          cursor: (count: number) => count + '',
        },
        elementType: 'bar',
        scaleType: 'linear',
      },
    ],
    [workPeriodsToday],
  )

  return (
    <Layout>
      <PageHeading
        title={currentOrganization?.name ? currentOrganization.name : ''}
        subtitle={
          currentOrganization?.type ? locales.organizations.type[currentOrganization?.type] : ''
        }
      ></PageHeading>
      {currentOrganizationRole === 'clocker' ? (
        <Section>
          <QrScannerPage />
        </Section>
      ) : (
        <Section>
          <Container>
            <Columns>
              <Columns.Column size={3}>
                <MissionsDashboardCard />
              </Columns.Column>
              <Columns.Column size={3}>
                <WorkPeriodsDashboardCard />
              </Columns.Column>
              <Columns.Column size={3}>
                <WorkersRequestsDashboardCard />
              </Columns.Column>
            </Columns>
            <Card>
              <Card.Content>
                <Heading textColor="grey" renderAs="h3" size={6} textWeight="bold" mb={5}>
                  <Element renderAs="span" style={{ color: '#0F83AB' }} textWeight="bold">
                    &#9632;
                  </Element>
                  ENTRÉES
                  {' / '}
                  <Element renderAs="span" style={{ color: '#FAA43A' }}>
                    &#9632;
                  </Element>{' '}
                  SORTIES
                </Heading>
                <Element style={{ height: 300 }}>
                  <Chart
                    options={{
                      data,
                      primaryAxis,
                      secondaryAxes,
                      initialHeight: 500,
                      initialWidth: 500,
                      interactionMode: 'closest',
                    }}
                  />
                </Element>
              </Card.Content>
            </Card>
          </Container>
        </Section>
      )}
    </Layout>
  )
}
export default EmployerDashboard
