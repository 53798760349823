import React from 'react'
import { useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import { useFirstRender } from '../hooks/use-first-render'
import useStore from '../store'
import { SessionSlice } from '../store/session'
import useUserLegalConditionsVersionsStatus from '../hooks/use-user-legal-conditions-status'
import { ProtectedAction, isProtectedFor } from '../components/protected/protected'
import {
  isAuthorizedForOption,
  OrganizationOptionProtectedProps,
} from '../components/protected/organization-option-protected'

interface AuthenticatedProps {
  roles?: SessionSlice['currentUserRole'][]
  action?: ProtectedAction
  organizationOptions?: OrganizationOptionProtectedProps['options']
  children: React.ReactNode
}

const Authenticated: React.FC<AuthenticatedProps> = ({
  children,
  roles,
  action,
  organizationOptions,
}) => {
  const navigate = useNavigate()
  const legalConditionsStatus = useUserLegalConditionsVersionsStatus()
  const [isAuthenticated, currentUserRole, currentOrganization, currentUser] = useStore(
    ({ session }) => [
      session.isAuthenticated,
      session.currentUserRole,
      session.currentOrganization,
      session.currentUser,
    ],
    shallow,
  )
  // Listen session data to check if current user can access to current route
  React.useEffect(() => {
    //Redirect to /login if current user is not authenticated (guest)
    if (!isAuthenticated) navigate('/login')
    //Redirect to privacy policy page when user has not yet accepted the latest version
    else if (legalConditionsStatus.privacyPolicy === 'outdated') navigate('/privacy-policy')
    //Redirect to privacy policy page when user has not yet accepted the latest version
    else if (legalConditionsStatus.termsOfUse === 'outdated') navigate('/terms-of-use')
    //Redirect to / when current user is authenticated but does not have the authorized role to access the current route
    else if (roles && isProtectedFor(roles, action)) navigate('/')
    else if (organizationOptions && !isAuthorizedForOption(organizationOptions)) navigate('/')
    else if (currentUserRole === 'guest') navigate('/errors/no-active-role')
  }, [isAuthenticated, roles, currentUserRole, legalConditionsStatus, organizationOptions])

  //Reload app when current user role change (change of profile, organization, login as...)
  const firstRender = useFirstRender()
  React.useEffect(() => {
    if (!firstRender) location.pathname = '/'
  }, [currentUserRole, currentOrganization?._id])
  return <>{currentUser && children}</>
}

export default Authenticated
