import { get, sortBy as _sortBy } from 'lodash'

interface ListInputItem {
  value?: string
  label: string
  item?: any
  renderedLabel?: React.ReactNode
}

export const buildListAsInputOptions = (
  list: any[],
  options?: {
    sortBy?: string | null | ((item: any) => boolean)
    labelKeyPath?: string
    valueKeyPath?: string
    labelBuilder?(item: any): React.ReactNode
    renderLabel?(item: any): React.ReactNode
    blank?: {
      label: string
      value?: string
    }
  },
): ListInputItem[] => {
  if (!options) options = {}
  const { blank, sortBy } = options
  if (blank && !blank.value) blank.value = ''

  const items: ListInputItem[] = list.map(listItem => {
    const sanitized: ListInputItem = {
      value:
        typeof listItem === 'string' ? listItem : get(listItem, options?.valueKeyPath || '_id'),
      label:
        typeof listItem === 'string'
          ? listItem
          : options?.labelBuilder
          ? options.labelBuilder(listItem)
          : get(listItem, options?.labelKeyPath || 'name'),
      item: listItem,
      renderedLabel: options?.renderLabel && options.renderLabel(listItem),
    }
    return sanitized
  })
  const itemsToReturn = [...(blank ? [blank] : []), ...items]
  if (sortBy === null) {
    return itemsToReturn
  }
  return _sortBy(itemsToReturn, sortBy ? sortBy : 'label')
}
