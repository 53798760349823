import React, { ReactNode } from 'react'
import { Container, Notification as BulmaNotification } from 'react-bulma-components'
import { Color } from 'react-bulma-components/src/components'

interface NotificationProps {
  type: Color
  children: ReactNode
}

//TODO: improve

const Notification: React.FC<NotificationProps> = ({ type, children }) => {
  return (
    <BulmaNotification color={type} mt={5}>
      <Container>{children}</Container>
    </BulmaNotification>
  )
}

export default Notification
