import { LoginIcon } from '@heroicons/react/outline'
import { DocumentAddIcon } from '@heroicons/react/solid'
import React from 'react'
import { Button, Container, Element, Form, Icon, Section } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { Column } from 'react-table'
import Layout from '../../components/layout/layout'
import ComponentHeader from '../../components/sections/component-header'
import PageHeading from '../../components/sections/page-heading'
import Table from '../../components/table'
import { useOrganizationsQuery } from '../../queries/organizations'
import useStore from '../../store'
import { InterimAgency } from '../../api/organizations'

const AgenciesList: React.FunctionComponent = () => {
  const interimAgencyQyery = useOrganizationsQuery<InterimAgency>({
    sort: 'name',
    type: 'interimAgency',
  })
  const tableColumns = useListTableColumns((interimAgencyQyery.data as InterimAgency[]) || [])

  return (
    <Layout>
      <PageHeading title="Liste des Agences d'Intérim" />
      <Section>
        <Container>
          <ComponentHeader>
            <ComponentHeader.Left></ComponentHeader.Left>
            <ComponentHeader.Right>
              <Form.Field>
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control>
                  <Button renderAs={Link} to="/agencies/new" outlined color="primary">
                    <Icon>
                      <DocumentAddIcon />
                    </Icon>
                    <span>Nouvelle agence</span>
                  </Button>
                  <Element></Element>
                </Form.Control>
              </Form.Field>
            </ComponentHeader.Right>
          </ComponentHeader>
          <Table
            columns={tableColumns}
            data={interimAgencyQyery.data || []}
            noDataMessage="Aucune agence à afficher"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default AgenciesList

const useListTableColumns = (organizations: InterimAgency[]): Column[] => {
  const loginAs = useStore(state => state.session.loginAs)
  return React.useMemo(
    () =>
      [
        {
          Header: 'Nom',
          accessor: 'name',
          Cell: (data: any) => {
            const organization: InterimAgency = data.cell.row.original
            return <Link to={`/agencies/${organization._id}`}>{organization.name}</Link>
          },
        },
        {
          Header: 'Coefficient',
          accessor: 'laborCostCoefficient',
          Cell: (data: any) => {
            const organization: InterimAgency = data.cell.row.original
            return organization.laborCostCoefficient || ''
          },
        },
        {
          Header: '',
          accessor: 'loginAs',
          Cell: (data: any) => {
            const organization: InterimAgency = data.cell.row.original
            return (
              <Button
                size="small"
                onClick={() => loginAs('interimAgencyMember', organization, 'admin')}
              >
                <Icon>
                  <LoginIcon />
                </Icon>
              </Button>
            )
          },
        },
      ] as Column[],
    [organizations],
  )
}
