import React from 'react'
import { Element } from 'react-bulma-components'
import { WorkersRequestPost } from '../../api/workers-requests'
import config from '../../../../backend/src/config'

const allRecourseCasesReasons = config.workersRequests.recourseCases.reasons

interface WorkersRequestRecourseCaseProps {
  recourseCase: WorkersRequestPost['recourseCase']
}

const WorkersRequestRecourseCase: React.FC<WorkersRequestRecourseCaseProps> = ({
  recourseCase,
}) => {
  return (
    <Element>
      <Element renderAs="span" textWeight="semibold">
        {allRecourseCasesReasons[recourseCase.reason]}{' '}
      </Element>
      <Element renderAs="span" italic>
        {recourseCase.justification}
      </Element>
    </Element>
  )
}

export default WorkersRequestRecourseCase
