import React from 'react'
import { Element } from 'react-bulma-components'
import { WorkersRequestHistoryItem } from '../../../api/workers-requests'
import Avatar from '../../avatar/avatar'

interface WorkersRequestHistoryItemCustomContentProps {
  historyItem?: WorkersRequestHistoryItem
}

const WorkersRequestHistoryItemCustomContent: React.FC<WorkersRequestHistoryItemCustomContentProps> =
  ({ historyItem }) => {
    return (
      <>
        {historyItem?.missions?.[0] && (
          <Element mt={4}>
            {historyItem.missions?.map((mission, index) => (
              <Element renderAs="ul" key={index}>
                <Element
                  key={mission._id}
                  renderAs="li"
                  style={{
                    textDecoration:
                      historyItem.action === 'missionReplaced' &&
                      index !== historyItem.missions.length - 1
                        ? 'line-through'
                        : 'none',
                  }}
                >
                  <Avatar {...mission.user} />{' '}
                </Element>
              </Element>
            ))}
          </Element>
        )}
      </>
    )
  }

export default WorkersRequestHistoryItemCustomContent
