/*
  TEMP HOOK: to be deleted when the summary table will be refactored and use directly data
  provided by sumlmary query
*/

import { useMemo } from 'react'
import { MissionSummary, MissionWithSummaries } from '../api/missions'
import { sumNumbersOfObjects } from '../../../backend/src/utils/object'
import sortBy from 'lodash/sortBy'
import { UserWeekSummary } from '../../../backend/src/services/resources/missions/missions.summary.service.types'

const useMissionsAndTotalsFromSummaryQuery = (
  data: UserWeekSummary[],
): { missions: MissionWithSummaries[]; totals: MissionSummary } => {
  const missionsWithSummaries = useMemo(() => {
    //@ts-ignore
    return data.reduce(
      //@ts-ignore
      (missions, summaryItem) => {
        if (summaryItem)
          return [
            ...missions,
            ...summaryItem.missions.map(mission => ({
              ...mission,
              summary: summaryItem.summary,
              weekSummary: summaryItem,
            })),
          ]
        return missions
      },
      [],
    ) as MissionWithSummaries[]
  }, [data])

  return {
    missions: sortBy(missionsWithSummaries, 'start'),
    totals: sumNumbersOfObjects<MissionSummary>(data.map(s => s.summary)),
  } // TODO: refactor table to use summary data correctly
}

export default useMissionsAndTotalsFromSummaryQuery
