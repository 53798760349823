import React from 'react'
import { Container, Element, Form, Icon, Section, Loader, Progress } from 'react-bulma-components'
import Layout from '../../components/layout/layout'
import { Mission } from '../../api/missions'
import { WorkPeriod } from '../../api/work-periods'
import MissionsFilters from '../../components/missions/filters'
import MissionValidateWeekConfirmation from '../../components/missions/modals/validate-week-confirmation'
import useStore from '../../store'
import ComponentHeader from '../../components/sections/component-header'
import WorkPeriodValidateConfirmation from '../../components/work-periods/modals/validate-confirmation'
import SummaryHeader from '../../components/summary/summary-header'
import Protected from '../../components/protected/protected'
import { useSummaryQuery } from '../../queries/missions'
import SummaryTable from '../../components/summary/summary-table'
import MissionCancelConfirmation from '../../components/missions/modals/cancel-confirmation'
import useMissionsAndTotalsFromSummaryQuery from '../../hooks/use-missions-from-summary-query'
import ExportButton from '../../components/summary/export-button'
import { convertDateToUTCKeepingTime, getWeek, getWeekYear } from '../../utils/date'
import CreatePreBillingModal from '../../components/pre-bilings/modals/create-pre-billing-modal'
import { PreBillingIcon } from '../../components/icons'
import { useModal } from '../../components/modal'
import ButtonWithTooltip from '../../components/button-with-tooltip/button-with-tooltip'
import { chunk, sortBy } from 'lodash'
import SummaryPagination from '../../components/summary/summary-pagination'
import { FiltersResourcesEnum } from '../../store/filters'

const missionsPerPage = 20

const Summary: React.FunctionComponent = () => {
  const filters = useStore(state => state.filters[FiltersResourcesEnum.missions])

  const { start, end, displayDay, summaryMinimalMode } = filters
  const pagination = useStore(state => state.filters.pagination.summary)
  const setPagination = useStore(state => state.filters.setPagination)

  const summaryQuery = useSummaryQuery()
  const missionsCount = (summaryQuery.data || []).filter(d => d.missions?.length).length

  const { missions, totals } = useMissionsAndTotalsFromSummaryQuery(
    chunk(sortBy(summaryQuery.data), 20)[pagination.currentPage - 1] || [],
  )

  React.useEffect(() => {
    // TODO: maybe managed by table component?
    const summaryItemsCount = summaryQuery.data?.length || 1
    if (summaryItemsCount / 20 < pagination.currentPage - 1) {
      setPagination('summary', { currentPage: 1 })
    }
  }, [pagination.currentPage, summaryQuery.data])

  //Validate mission confirmation state
  const [currentMissionToValidate, setCurrentMissionToValidate] = React.useState<Mission>()
  //Validate workPeriod confirmation state
  const [currentWorkPeriodToValidate, setCurrentWorkPeriodToValidate] = React.useState<WorkPeriod>()
  //Cancel mission (work period) confirmation state
  const [currentMissionToCancel, setCurrentMissionToCancel] = React.useState<Mission>()

  //Pre billing modal state
  const preBillingModal = useModal()
  const preBillingDisabled = filters.interimAgency?.length !== 1

  const week = getWeek(start)
  const year = getWeekYear(start as Date)

  return (
    <Layout>
      {!preBillingDisabled && filters.interimAgency?.[0] && (
        <CreatePreBillingModal
          {...preBillingModal}
          interimAgencyId={filters.interimAgency[0]}
          start={start}
          end={end}
          service={filters.service}
          jobTitle={filters.jobTitle}
        />
      )}
      <Section pb={2} px={1} pt={5}>
        <Container breakpoint={'fluid'}>
          <ComponentHeader mb={3}>
            <ComponentHeader.Left>
              <Element backgroundColor="primary-light" style={{ borderRadius: '5px' }} p={3}>
                <MissionsFilters
                  showDayFilter
                  showSummaryMinimalModeFilter
                  disabledQueries={summaryQuery.isLoading}
                />
              </Element>
            </ComponentHeader.Left>
            <ComponentHeader.Right>
              <Form.Field>
                <Form.Control>
                  <Element renderAs="span" mr={2}>
                    <ExportButton
                      filters={{
                        ...filters,
                        start: filters.start && convertDateToUTCKeepingTime(filters.start),
                        end: filters.end && convertDateToUTCKeepingTime(filters.end),
                      }}
                    />
                  </Element>
                </Form.Control>
              </Form.Field>
              <Protected
                roles={['employerMember']}
                action={{ resource: 'preBillings', name: 'create' }}
              >
                <ButtonWithTooltip
                  fullwidth
                  onClick={() => preBillingModal.setIsDisplayed(true)}
                  color={'primary'}
                  disabled={preBillingDisabled}
                  tooltip={{
                    text: "Séléctionner une seul agence d'intérim pour créer une pré-facturation",
                    show: preBillingDisabled,
                    id: 'pre-billing-action',
                  }}
                >
                  <Icon>
                    <PreBillingIcon />
                  </Icon>
                  <span>Pré-facturation</span>
                </ButtonWithTooltip>
              </Protected>
            </ComponentHeader.Right>
          </ComponentHeader>
        </Container>
      </Section>
      <Container breakpoint={'fluid'}>
        <Section p={4} backgroundColor="white-bis">
          <SummaryHeader
            missions={missions}
            startOfWeek={start as Date}
            endOfWeek={end as Date}
            interimAgencyId={
              filters.interimAgency?.length === 1 ? filters.interimAgency[0] : undefined
            }
            //service={filters.service}
            //jobTitle={filters.jobTitle}
            totals={totals}
          />
        </Section>
        <Section className="summary-table" style={{ padding: 0 }}>
          {!summaryQuery.isLoading && (
            <SummaryPagination
              currentPage={pagination.currentPage}
              missionsPerPage={missionsPerPage}
              missionsCount={missionsCount}
              onChange={currentPage => setPagination('summary', { currentPage })}
            />
          )}
          <Container breakpoint={'fluid'} style={{ padding: 0 }} mt={5}>
            <Element textSize={7}>
              {!summaryQuery.isLoading ? (
                <SummaryTable
                  missions={missions}
                  setCurrentMissionToValidate={setCurrentMissionToValidate}
                  setCurrentWorkPeriodToValidate={setCurrentWorkPeriodToValidate}
                  setCurrentMissionToCancel={setCurrentMissionToCancel}
                  start={start}
                  end={end}
                  summaryMinimalMode={summaryMinimalMode}
                  displayDay={displayDay}
                />
              ) : (
                <Element display="flex" justifyContent="center" flexDirection="column">
                  <Element textSize={5} display="flex" justifyContent="center" my={5}>
                    <Icon.Text>
                      <Loader mr={5} />
                      Mise à jour des données du récapitulatif
                    </Icon.Text>
                  </Element>
                  <Container breakpoint={'widescreen'}>
                    <Progress color="primary" size={'small'} px={6} />
                  </Container>
                </Element>
              )}
            </Element>
          </Container>
          {!summaryQuery.isLoading && (
            <SummaryPagination
              currentPage={pagination.currentPage}
              missionsPerPage={missionsPerPage}
              missionsCount={missionsCount}
              onChange={currentPage => setPagination('summary', { currentPage })}
            />
          )}
        </Section>
      </Container>
      <Protected roles={['employerMember']}>
        {currentMissionToValidate && (
          <MissionValidateWeekConfirmation
            mission={currentMissionToValidate}
            hide={() => setCurrentMissionToValidate(undefined)}
            year={year}
            week={week}
          />
        )}
        {currentWorkPeriodToValidate && (
          <WorkPeriodValidateConfirmation
            workPeriod={currentWorkPeriodToValidate}
            hide={() => setCurrentWorkPeriodToValidate(undefined)}
          />
        )}
        {currentMissionToCancel && (
          <MissionCancelConfirmation
            mission={currentMissionToCancel}
            hide={() => setCurrentMissionToCancel(undefined)}
            show={Boolean(currentMissionToCancel)}
            workPeriod={
              currentMissionToCancel.workPeriods.find(
                workPeriod => workPeriod.__actions.canBeCancelled,
              ) as WorkPeriod
            }
          />
        )}
      </Protected>
    </Layout>
  )
}

export default Summary
