import React from 'react'
import { Field, Form, Formik } from 'formik'
import { Card, Element, Heading, Icon, Loader } from 'react-bulma-components'
import AutocompleteField from '../form/fields/autocomplete'
import { buildListAsInputOptions } from '../../utils/forms'
import { useAvailableWorkersQuery } from '../../queries/users'
import { WorkersRequest, WorkersRequestPost } from '../../api/workers-requests'
import { AvailableWorker } from '../../api/users'
import { RequestButton } from '../request-components/request-components'
import { useAssignWorkerToPost } from '../../queries/workers-requests'
import useStore from '../../store'
import Protected from '../protected/protected'
import { ThumbUpIcon } from '../icons'
import { checkAvailibilityInPeriod, getWorkerAbsentLabel, getUserLabel } from '../../utils/users'
import { truncate } from 'lodash'
import WorkerLabel from '../texts/worker-label'
import { UserInputLabel } from '../missions/mission-form'

interface AssignWorkerFormProps {
  workersRequest: WorkersRequest
  postId: string
}

const AssignWorkerForm: React.FC<AssignWorkerFormProps> = ({ workersRequest, postId }) => {
  const { currentUserRole, currentUser, currentOrganization } = useStore(state => state.session)
  const availableWorkers = useAvailableWorkersQuery(
    {
      workersRequestId: workersRequest._id,
    },
    { enabled: currentUserRole === 'interimAgencyMember' },
  )

  const assignWorkerToPostMutation = useAssignWorkerToPost()
  const post = workersRequest.posts.find(post => post._id === postId)

  if (!currentUser) return <Loader />
  if (!post) return <>Something went wrong</>

  return (
    <Formik
      initialValues={{
        userId: '',
        postId,
        workersRequestId: workersRequest._id.toString(),
        updatedBy: currentUser._id.toString(),
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.userId) errors.userId = 'Champ requis'
        else {
          const worker = availableWorkers.data?.find(w => w._id === values.userId)
          const workerIsAvailable =
            worker &&
            checkAvailibilityInPeriod(
              new Date(workersRequest.missionsStart),
              new Date(workersRequest.missionsEnd),
              worker.availability,
            )
          if (!workerIsAvailable && worker && currentOrganization)
            errors.userId = getWorkerAbsentLabel(worker.availability, worker)
        }
        return errors
      }}
      onSubmit={values => {
        return assignWorkerToPostMutation.mutate(values)
      }}
    >
      {props => {
        const selectedWorker = availableWorkers.data?.find(
          w => w._id.toString() === props.values.userId,
        )
        return (
          <Form>
            {availableWorkers.data && availableWorkers.data[0] ? (
              <Field
                name="userId"
                component={AutocompleteField}
                autoComplete="off"
                required
                onlySelect
                placeholder="Rechercher"
                items={buildListAsInputOptions(availableWorkers.data || [], {
                  labelBuilder: (user: AvailableWorker) => getUserLabel(user),
                  renderLabel: (user: AvailableWorker) => getInputLabel(user, post, workersRequest),
                  sortBy: item => typeof item.renderedLabel === 'string',
                })}
                disabled={
                  !availableWorkers.data ||
                  !availableWorkers.data[0] ||
                  !workersRequest.__actions.missionsCanBeCreated
                }
                mb={0}
              />
            ) : (
              <Element
                italic
                backgroundColor="light"
                textColor="grey"
                py={2}
                px={5}
                style={{ borderRadius: 4 }}
              >
                <Protected roles={['interimAgencyMember']}>
                  Aucun <WorkerLabel /> disponible
                </Protected>
                <Protected roles={['employerMember']}>
                  Aucun <WorkerLabel /> assigné
                </Protected>
              </Element>
            )}
            {props.values.userId && (
              <>
                <br />
                {currentOrganization && selectedWorker && (
                  <Card>
                    <Card.Content>
                      <UserInputLabel user={selectedWorker} organization={currentOrganization} />
                    </Card.Content>
                  </Card>
                )}
                <br />
                <RequestButton mutation={assignWorkerToPostMutation} type="submit">
                  Assigner
                </RequestButton>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

const getInputLabel = (
  user: AvailableWorker,
  post: WorkersRequestPost,
  workersRequest: WorkersRequest,
): string | React.ReactNode => {
  const userLabel = getUserLabel(user)
  const isAvailableForWorkersRequest = checkAvailibilityInPeriod(
    new Date(workersRequest.missionsStart),
    new Date(workersRequest.missionsEnd),
    user.availability,
  )

  const absentLabel =
    !isAvailableForWorkersRequest && getWorkerAbsentLabel(user.availability, user, true)

  const label = (
    <>
      {userLabel}
      {absentLabel && (
        <>
          <br />
          <Element renderAs="small">Absent {absentLabel.toLowerCase()}</Element>
        </>
      )}
      {user.internalComment && (
        <Element>
          <Element renderAs="small">{truncate(user.internalComment, { length: 70 })}</Element>
        </Element>
      )}
      {user.internalInformation && (
        <Element>
          <Element renderAs="small">{truncate(user.internalInformation, { length: 70 })}</Element>
        </Element>
      )}
    </>
  )

  // @ts-ignore
  if (post.desiredWorkers.some(desiredWorker => desiredWorker?._id === user._id))
    return (
      <Element renderAs="span">
        <Icon size={'small'}>
          <ThumbUpIcon />
        </Icon>{' '}
        {label}
      </Element>
    )
  return label
}

export default AssignWorkerForm
