import React from 'react'
import { Element, Icon } from 'react-bulma-components'
import { Mission } from '../../../api/missions'
import { useUpdateMutation } from '../../../queries/missions'
import { formatCompleteDateTime } from '../../../utils/date'
import Avatar from '../../avatar/avatar'
import ConfirmationModal, {
  ConfirmationModalBaseProps,
} from '../../confirmation-modal/confirmation-modal'
import { isAfter } from 'date-fns'
import { hasAllWorkPeriodsClocked } from '../../../utils/missions'
import { WarningIcon } from '../../icons'

interface MissionValidateConfirmationProps extends ConfirmationModalBaseProps {
  mission: Mission
}

const MissionValidateConfirmation: React.FunctionComponent<MissionValidateConfirmationProps> = ({
  mission,
  hide,
}) => {
  const mutation = useUpdateMutation()

  const workPeriods = mission.workPeriods
  const lastWorkPeriod = workPeriods[workPeriods.length - 1]

  const onConfirm = async () => {
    await mutation.mutate({
      _id: mission._id,
      isValidated: true,
    })

    hide()
  }

  const hasNotStartedWorkPeriod =
    lastWorkPeriod && isAfter(new Date(lastWorkPeriod.start.date), new Date())

  const hasNotAllWorkPeriodsClocked = !hasAllWorkPeriodsClocked({ ...mission, workPeriods })

  return (
    <ConfirmationModal show={Boolean(mission)} onConfirm={onConfirm} onCancel={hide}>
      <Element>
        <Element renderAs="p" mb={3}>
          Valider l'entièreté de la Mission : <br />
        </Element>
        {workPeriods[0] && lastWorkPeriod && (
          <Element renderAs="strong">
            <Avatar {...mission.user} />
            <br />
            Du {formatCompleteDateTime(workPeriods[0].start.date)} au{' '}
            {formatCompleteDateTime(lastWorkPeriod.end.date)}
          </Element>
        )}
      </Element>
      {(hasNotStartedWorkPeriod || hasNotAllWorkPeriodsClocked) && (
        <Element mt={4}>
          <Icon.Text>
            <Icon>
              <WarningIcon />
            </Icon>
            <Element renderAs="span" textWeight="bold">
              Attention
            </Element>
          </Icon.Text>
          {hasNotStartedWorkPeriod && <Element>La semaine n'est pas encore terminée</Element>}
          {hasNotAllWorkPeriodsClocked && (
            <Element>Toutes les journées de travail n'ont pas été pointées</Element>
          )}
        </Element>
      )}
    </ConfirmationModal>
  )
}

export default MissionValidateConfirmation
