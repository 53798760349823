export const moveItemInArray = (arr: any[], fromIndex: number, toIndex: number): any[] => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

const enumToArrayOfStrings = (enumeration: { [key: string]: any }): string[] => {
  return Object.keys(enumeration).map((key: any) => enumeration[key])
}
