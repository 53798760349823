import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Columns, Container, Element, Heading } from 'react-bulma-components'
import { useCreateMutation, useUpdateMutation } from '../../queries/organizations'
import { InterimAgency, OrganizationTypeEnum } from '../../api/organizations'
import InputField from '../form/fields/input'
import { RequestButton, RequestMessage } from '../request-components/request-components'
import { AddressesForm } from './addresses-form'

interface AgencyFormProps {
  organization?: InterimAgency
}

const AgencyForm: React.FunctionComponent<AgencyFormProps> = ({ organization }) => {
  const createMutation = useCreateMutation()
  const updateMutation = useUpdateMutation()
  const currentMutation = organization ? updateMutation : createMutation
  return (
    <Formik
      initialValues={{
        name: organization?.name || '',
        type: OrganizationTypeEnum.interimAgency,
        laborCostCoefficient: organization?.laborCostCoefficient,
        addresses: organization?.addresses ?? [],
      }}
      enableReinitialize
      validate={values => {
        const errors: any = {}
        if (!values.name) errors.name = 'Champ requis'
        if (!values.laborCostCoefficient) errors.laborCostCoefficient = 'Champ requis'
        if (values.addresses.length === 0)
          errors.addresses = 'Au moins une adresse doit être renseignée'
        return errors
      }}
      onSubmit={values => {
        if (!organization) createMutation.mutate(values)
        else updateMutation.mutate({ id: organization._id as string, ...values })
      }}
    >
      {() => {
        return (
          <Form>
            <Columns>
              <Columns.Column size={12}>
                <Heading renderAs="h2" size={4}>
                  Données de l'agence
                </Heading>
                <Field label="Nom" name="name" component={InputField} required />
                <AddressesForm />
                <Field
                  label="Coefficient main d'oeuvre"
                  name="laborCostCoefficient"
                  component={InputField}
                  type="number"
                  min={0}
                  step={0.001}
                  required
                />
              </Columns.Column>
            </Columns>
            <Container>
              <Element mt={6}>
                <Element mb={1}>
                  <RequestMessage mutation={currentMutation} />
                </Element>
                <RequestButton color="primary" type="submit" mutation={currentMutation}>
                  {organization
                    ? "Mettre à jour les données de l'Agence"
                    : 'Créer une Nouvelle Agence'}
                </RequestButton>
              </Element>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AgencyForm
